import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectItem from '../layout/shared/SelectItem';
import { searchHeaderShape } from '../../types';
import stateSearch from '../../services/stateSearch.service';
import Loader from '../shared/loader/Loader';
import DataTableComponent from '../shared/dataTable/DataTableComponent';

const StateSelector = ({
  initialState,
  searchHeader,
  states,
  setShowContentDivider,
  showDataTableContentDivider
}) => {
  const [dataRows, setDataRows] = useState([]);
  const [defaultValue, setDefaultValue] = useState('default');
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const setUrl = values => {
    let currentUrl = window.location.href;

    if (currentUrl.includes('stateid')) {
      // Remove it
      const index = currentUrl.indexOf('&stateid') || currentUrl.indexOf('?stateid');
      currentUrl = currentUrl.substring(0, index);
    }

    if (currentUrl.includes('?')) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, 'Filter Update', `${currentUrl}&stateid=${values}`);
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, 'Filter Update', `${currentUrl}?stateid=${values}`);
    }
  };

  const getStateInfo = async values => {
    setLoading(true);
    setUrl(values);

    const response = await stateSearch(values.split(','));

    setDataRows(response);
    setShowContentDivider(false);
    setLoading(false);
  };

  if (!isPageLoaded) {
    setIsPageLoaded(true);

    if (initialState) {
      getStateInfo(initialState);
      setDefaultValue(initialState);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <div className="state-selector-container">
        <div className="state-selector">
          <h3>{searchHeader.title}</h3>
          <p>{searchHeader.subTitle}</p>
          <div className="form-group select-input">
            <label className="sr-only" htmlFor="states">
              State Selector
            </label>
            <select
              id="states"
              name="states"
              defaultValue={defaultValue}
              onClick={() => setIsExpanded(!isExpanded)}
              onChange={selected => getStateInfo(selected.target.value)}
            >
              <option value="default" disabled hidden>
                Select a State
              </option>
              {states.length > 0 &&
                states.map(state => (
                  <SelectItem key={state.title} value={state.iDs.toString()} title={state.title} />
                ))}
            </select>
          </div>
        </div>
      </div>
      {dataRows && dataRows.length > 0 && (
        <DataTableComponent rows={dataRows} showContentDivider={showDataTableContentDivider} />
      )}
    </>
  );
};

StateSelector.defaultProps = {
  initialState: '',
  searchHeader: {},
  showDataTableContentDivider: true
};

StateSelector.propTypes = {
  initialState: PropTypes.string,
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  setShowContentDivider: PropTypes.func.isRequired,
  showDataTableContentDivider: PropTypes.bool
};

export default StateSelector;
