import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sizedImageShape } from '../../../types';
import { useCssVariables } from '../../../utilities';

const TestimonialDesktop = ({ altText, author, colorScheme, icon, image, quote }) => {
  const testimonialImageRef = useRef(null);

  useCssVariables(testimonialImageRef, {
    '--background-image': `url("${image.url}")`
  });

  return (
    <div className={classNames('testimonial br-md-flx', colorScheme)}>
      {image.url && <div ref={testimonialImageRef} className="testimonial-image" />}
      <div className="testimonial-body">
        {icon && <img className="icon" src={icon} alt={altText || 'icon'} />}
        <h2 dangerouslySetInnerHTML={{ __html: quote }} />
        <h3>{author}</h3>
      </div>
    </div>
  );
};

TestimonialDesktop.defaultProps = {
  altText: '',
  icon: null,
  image: null
};

TestimonialDesktop.propTypes = {
  altText: PropTypes.string,
  author: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
  icon: PropTypes.string,
  image: sizedImageShape,
  quote: PropTypes.string.isRequired
};

export default TestimonialDesktop;
