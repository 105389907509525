import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import FeaturedCard from '../../shared/card/FeaturedCard';
import GlobalComponentContainer from '../GlobalComponentContainer';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const FeaturedContentNewsroomComponent = ({
  associatedNewsroomURL,
  featuredItems,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="newsroom-featured section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
            <div className="newsroom-list">
              {featuredItems.map(item => (
                <FeaturedCard
                  altText={item.altText}
                  businessUnitTitle={item.businessUnitTitle}
                  contentType={item.contentType}
                  key={item.link}
                  link={item.link}
                  title={item.title}
                  thumbnailImage={item.thumbnailImage}
                  url={associatedNewsroomURL}
                />
              ))}
            </div>
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

FeaturedContentNewsroomComponent.defaultProps = {
  featuredItems: [],
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  shortDescription: ''
};

FeaturedContentNewsroomComponent.propTypes = {
  associatedNewsroomURL: PropTypes.string.isRequired,
  featuredItems: PropTypes.arrayOf(PropTypes.object),
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default FeaturedContentNewsroomComponent;
