import React from 'react';
import PropTypes from 'prop-types';

import SitemapListItem from './SitemapListItem';
import { menuItemShape } from '../../types';

const SitemapLanding = ({ mainMenu, legalMenu, searchUrl }) => (
  <div className="sitemap-landing section-white">
    <div className="container-fluid">
      <div className="wrapper">
        {mainMenu.map(megaMenuItem => (
          <section key={megaMenuItem.name}>
            <h2>{megaMenuItem.name}</h2>
            <ul>
              {megaMenuItem?.children && megaMenuItem.children.length ? (
                megaMenuItem.children.map(menuItem => (
                  <SitemapListItem
                    url={menuItem.url}
                    name={menuItem.name}
                    key={menuItem.url + menuItem.name}
                  />
                ))
              ) : (
                <SitemapListItem url={megaMenuItem.url} name={megaMenuItem.name} />
              )}
            </ul>
          </section>
        ))}
        <section>
          <h2>Other</h2>
          <ul>
            {legalMenu
              .filter(
                // Probably not the best solution. However, this is not a page that will be changed much, if at all.
                menuItem =>
                  !(
                    menuItem.name.toLowerCase().includes('sitemap') ||
                    menuItem.url.toLowerCase().includes('sitemap')
                  )
              )
              .map(menuItem => (
                <SitemapListItem
                  url={menuItem.url}
                  name={menuItem.name}
                  key={menuItem.url + menuItem.name}
                />
              ))}
            <SitemapListItem url={searchUrl} name="Search" />
          </ul>
        </section>
      </div>
    </div>
  </div>
);

SitemapLanding.defaultProps = {
  mainMenu: [],
  legalMenu: [],
  searchUrl: ''
};

SitemapLanding.propTypes = {
  mainMenu: PropTypes.arrayOf(menuItemShape),
  legalMenu: PropTypes.arrayOf(menuItemShape),
  searchUrl: PropTypes.string
};

export default SitemapLanding;
