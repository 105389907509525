import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import localStorage from 'local-storage';

import SearchBarComponent from '../widgets/searchBar/SearchBarComponent';
import AnchorWrapper from '../layout/shared/AnchorWrapper';
import PageWasLoadedFromBackOrForwardOperation from '../../services/browser.service';

import Pager from '../shared/pager/Pager';

import { getSearch, submitClick } from '../../services/search.service';

const ClientSearchLanding = ({
  alerts,
  initialSearchText,
  noResultsText,
  searchLinks,
  microSitePath
}) => {
  const STOREDSEARCHPARAMETERS = 'storedSearchParameters';
  const ALL = { audiences: null, key: 'All', title: 'All' };
  const itemsPerPage = 10;
  const [searchItems, setSearchItems] = useState();
  const [searchText, setSearchText] = useState();
  const [searchId, setSearchId] = useState();
  const [pagerInfo, setPagerInfo] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const showResults = searchItems && searchItems.length > 0;
  const hasAlerts = !!alerts && !!alerts.length;

  const search = async (query, page) => {
    localStorage.set(STOREDSEARCHPARAMETERS, {
      query,
      page
    });

    setCurrentPage(page);
    setSearchText(query);

    const response = await getSearch(query, page, itemsPerPage, null, null, microSitePath);

    if (response) {
      setSearchItems(response.listing.items);
      setPagerInfo(response.listing.pager);
      setSearchId(response.searchId);

      scroller.scrollTo('scroll', {
        smooth: true,
        duration: 500,
        delay: 150,
        offset: hasAlerts ? -200 : -150
      });
    }
  };

  const getItemCountRange = () => {
    const start = (pagerInfo.currentPage - 1) * itemsPerPage + 1;
    const end =
      pagerInfo.currentPage * pagerInfo.pageSize < pagerInfo.totalItems
        ? pagerInfo.currentPage * pagerInfo.pageSize
        : pagerInfo.totalItems;

    return `${start}-${end}`;
  };

  const onSearch = async (query, page) => {
    await search(query, page, microSitePath);
  };

  const submitClickAndNavigate = item => {
    const newItem = item;
    newItem.searchId = searchId;

    submitClick(newItem);

    window.location.href = item.url;
  };

  if (!isPageLoaded) {
    setIsPageLoaded(true);

    const storedSearchParameters = localStorage.get(STOREDSEARCHPARAMETERS);
    localStorage.remove(STOREDSEARCHPARAMETERS);

    if (storedSearchParameters != null && PageWasLoadedFromBackOrForwardOperation()) {
      search(
        storedSearchParameters.query,
        storedSearchParameters.page,
        storedSearchParameters.businessUnitFilter == null
          ? ALL
          : storedSearchParameters.businessUnitFilter,
        storedSearchParameters.searchFilter
      );
    } else if (initialSearchText) {
      search(initialSearchText, currentPage);
    }
  }

  return (
    <>
      <div className="search-landing section-white">
        <section className="search-bar">
          <SearchBarComponent
            colorScheme="light"
            isFloatingBetweenComponents={false}
            isFocused
            onSearch={onSearch}
            query={searchText}
            redirectToSearch={false}
            searchLinks={[]}
            title=""
            microSitePath={microSitePath}
          />
        </section>
        <Element name="scroll" />
        {showResults && pagerInfo && (
          <div className="count">
            <div className="container-fluid">
              <span className="section-header">
                {`${getItemCountRange()} of
                  ${pagerInfo.totalItems}
                  Items Matching your Search Criteria`}
              </span>
            </div>
          </div>
        )}

        <section className="section-gutter">
          <div className="container-fluid">
            <div className="search-list">
              {showResults ? (
                searchItems.map((item, index) => (
                  <div className="result" key={item.id}>
                    <button
                      className="btn-no-style"
                      type="button"
                      title="Search Result"
                      onClick={() =>
                        submitClickAndNavigate({
                          rank: index + (currentPage - 1) * itemsPerPage,
                          searchItemId: item.searchItemId,
                          url: item.url
                        })
                      }
                    >
                      <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                      <span className="url">{item.url}</span>
                      <p dangerouslySetInnerHTML={{ __html: item.content }} />
                      {item.businessUnitTitle && (
                        <span className="tag">{item.businessUnitTitle}</span>
                      )}
                    </button>
                  </div>
                ))
              ) : (
                <>
                  <span
                    className="no-results text-center"
                    dangerouslySetInnerHTML={{ __html: noResultsText }}
                  />
                  {searchLinks && (
                    <span className="search-links">
                      {searchLinks.map(link => (
                        <AnchorWrapper
                          url={link.url}
                          target={link.target}
                          key={link.name}
                          name={link.name}
                        >
                          <>{link.name}</>
                        </AnchorWrapper>
                      ))}
                    </span>
                  )}
                </>
              )}
            </div>
            {showResults && pagerInfo && (
              <Pager
                search={search}
                searchText={searchText}
                currentPage={currentPage}
                pagerInfo={pagerInfo}
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

ClientSearchLanding.defaultProps = {
  alerts: [],
  initialSearchText: '',
  searchLinks: []
};

ClientSearchLanding.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  initialSearchText: PropTypes.string,
  noResultsText: PropTypes.string.isRequired,
  searchLinks: PropTypes.arrayOf(PropTypes.object),
  microSitePath: PropTypes.string.isRequired
};

export default ClientSearchLanding;
