import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Element } from 'react-scroll';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '../GlobalComponentContainer';
import TestimonialDesktop from './TestimonialDesktop';
import TestimonialMobile from './TestimonialMobile';
import SliderArrow from '../../shared/slick/SliderArrow';

const TestimonialComponent = ({
  testimonials,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  leftArrow,
  rightArrow
}) => {
  const settings = {
    prevArrow: <SliderArrow className="slick-arrow slick-prev" to="prev" img={leftArrow} />,
    nextArrow: <SliderArrow className="slick-arrow slick-next" to="next" img={rightArrow} />
  };

  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="testimonial-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
            <Slider {...settings}>
              {testimonials.map(testimonial => (
                <div key={testimonial.quote} className="panel">
                  <TestimonialDesktop {...testimonial} />
                  <TestimonialMobile {...testimonial} />
                </div>
              ))}
            </Slider>
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

TestimonialComponent.defaultProps = {
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  shortDescription: '',
  title: '',
  leftArrow: '',
  rightArrow: ''
};

TestimonialComponent.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  leftArrow: PropTypes.string,
  rightArrow: PropTypes.string
};

export default TestimonialComponent;
