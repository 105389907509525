import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { useCssVariables } from '../../../utilities';

const QuickCtaComponent = ({
  alignment,
  backgroundColor,
  ctaButtonBackgroundColor,
  ctaButtonTarget,
  ctaButtonText,
  ctaButtonTextColor,
  ctaButtonUrl,
  prettyUrlTitle,
  title,
  titleTextColor
}) => {
  const quickCtaRef = useRef(null);

  useCssVariables(quickCtaRef, {
    '--background-color': `${backgroundColor || 'unset'}`,
    '--text-color': `${titleTextColor || 'unset'}`,
    '--cta-background-color': `${ctaButtonBackgroundColor || 'unset'}`,
    '--cta-text-color': `${ctaButtonTextColor || 'unset'}`
  });

  return (
    <Element name={prettyUrlTitle}>
      <section className="section-white quick-cta" ref={quickCtaRef}>
        <div className={`container-fluid ${alignment}`}>
          <div className="inner wrapper">
            <div className="content" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="cta">
              <div className="btn-container">
                <a className="btn" href={ctaButtonUrl} target={ctaButtonTarget}>
                  {ctaButtonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

QuickCtaComponent.defaultProps = {
  ctaButtonTarget: '',
  prettyUrlTitle: ''
};

QuickCtaComponent.propTypes = {
  alignment: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  ctaButtonBackgroundColor: PropTypes.string.isRequired,
  ctaButtonTarget: PropTypes.string,
  ctaButtonText: PropTypes.string.isRequired,
  ctaButtonTextColor: PropTypes.string.isRequired,
  ctaButtonUrl: PropTypes.string.isRequired,
  prettyUrlTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleTextColor: PropTypes.string.isRequired
};

export default QuickCtaComponent;
