import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '../../shared/card/Card';
import Loader from '../../shared/loader/Loader';
import { newsroomSearch, submitClick } from '../../../services/newsroomSearch.service';
import getQueryStringParameters from '../../../services/queryString.service';

import SearchFilters from '../../shared/searchFilters/SearchFilters';
import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';

import { SHOW_MORE, FEATURED_TITLE } from '../../../constants/newsroom';

const NewsroomLanding = ({ businessUnitTitle }) => {
  const [allItems, setAllItems] = useState([]);
  const [apiResponse, setApiResponse] = useState();
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [searchId, setSearchId] = useState();

  let itemsPerPage = 20;

  const totalItems = apiResponse && apiResponse.listing.pager.totalItems;
  const featuredItems = allItems && allItems.filter(item => item.isFeatured);
  const items = allItems && allItems.filter(item => !item.isFeatured);
  const isBusinessUnit = businessUnitTitle === '';
  const showFeaturedSection = featuredItems && !!featuredItems.length;
  let filterCount = 0;

  if (apiResponse) {
    const filterResponse = apiResponse.filters;
    filterCount = filterResponse
      .map(section => section.items.filter(filter => filter.isSelected === true).length)
      .reduce((a, b) => a + b, 0);
  }

  const getNewsroomData = (pageNumber, filterList, pageChanged) => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!pageChanged) {
        setPage(1);
      }
      const response = await newsroomSearch(
        filterList,
        itemsPerPage,
        pageNumber,
        businessUnitTitle !== ''
      );
      setApiResponse(response);
      setAllItems(existingItems =>
        existingItems && pageChanged
          ? existingItems.concat(response.listing.items)
          : response.listing.items
      );
      setSearchId(response.searchId);
      setIsLoading(false);
    };
    fetchData();
  };

  const isShowMoreVisible = () => {
    return items && totalItems > items.length;
  };

  const setUrl = filtersList => {
    let currentUrl = window.location.href;
    let currentFilters = filtersList;

    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
    }

    if (businessUnitTitle) {
      currentFilters = filtersList.filter(filter => filter.type !== 'businessunittitle');
    }

    if (currentFilters) {
      const props = currentFilters
        .filter(filter => filter.values.length > 0)
        .map(filter => `&${filter.type}=${filter.values.join()}`)
        .join('')
        .slice(1);

      if (!props) {
        // eslint-disable-next-line no-restricted-globals
        history.pushState({}, 'Filter Update', `${currentUrl}`);
      } else {
        // eslint-disable-next-line no-restricted-globals
        history.pushState({}, 'Filter Update', `${currentUrl}?${props}`);
      }
    }
  };

  const modifyFilters = (originalFilters, type, value, removeOthers) => {
    const filtersList = [...originalFilters];
    if (type === '' || value === '') {
      return originalFilters;
    }

    if (filtersList && filtersList.some(item => item.type === type)) {
      const item = filtersList.filter(filter => filter.type === type)[0];
      const splitValues = value.split(',');

      if (item.values.length === 1) {
        item.values = item.values[0].split(',');
      }

      splitValues.forEach(v => {
        const itemIndex = item.values.indexOf(v);
        if (itemIndex === -1) {
          if (removeOthers) {
            item.values = [v];
          } else {
            item.values.push(v);
          }
        } else if (!removeOthers) {
          item.values.splice(itemIndex, 1);
        }
      });
    } else {
      filtersList.push({ type, values: [value] });
    }

    setUrl(filtersList);

    return filtersList;
  };

  const modifyFiltersOnClick = (type, value, removeOthers) => {
    let filtersList = [...filters];

    filtersList = modifyFilters(filtersList, type, value, removeOthers);

    return filtersList;
  };

  const clearFilters = () => {
    let newFilters = [];
    if (businessUnitTitle !== '') {
      newFilters = filters.filter(item => item.type === 'businessunittitle');
    }
    setFilters(newFilters);
    setUrl(newFilters);
    return newFilters;
  };

  const submitClickAndNavigate = item => {
    const newItem = item;
    newItem.searchId = searchId;

    submitClick(newItem);

    window.location.href = item.link;
  };

  if (!pageLoaded) {
    setPageLoaded(true);

    getQueryStringParameters().then(queryStringParameters => {
      let filterList = [];

      Object.keys(queryStringParameters).forEach(key => {
        const values = queryStringParameters[key];
        values.split(',').forEach(value => {
          filterList = modifyFilters(filterList, key, value, false);
        });
      });

      if (businessUnitTitle !== '') {
        filterList = modifyFilters(filterList, 'businessunittitle', businessUnitTitle, true);
      }

      setFilters(filterList);

      getNewsroomData(1, filterList, false);
    });
  }

  return (
    <>
      <div className="newsroom-landing section-white">
        {isLoading && <Loader />}
        <section className="util">
          <SearchFilters
            allItems={allItems}
            clearFilters={clearFilters}
            getData={getNewsroomData}
            filterCount={filterCount}
            isLoaded={!isLoading}
            modifyFilters={modifyFiltersOnClick}
            page={page}
            response={apiResponse}
            setFilters={setFilters}
            isBusinessUnit={isBusinessUnit}
          />
        </section>

        {items && totalItems ? (
          <div className="count">
            <div className="container-fluid">
              <span className="section-header">{`1-${items.length} of ${totalItems} Items`}</span>
            </div>
          </div>
        ) : (
          ''
        )}

        {showFeaturedSection && (
          <section
            className={`section-gutter section-offset ${items && totalItems ? ' featured' : ''}`}
          >
            <div className="container-fluid">
              <h2>{FEATURED_TITLE}</h2>
              <div className="newsroom-list">
                {featuredItems &&
                  featuredItems.map((item, index) => (
                    <Card
                      altText={item.altText}
                      businessUnitTitle={item.businessUnitTitle}
                      contentType={item.contentType}
                      key={item.link}
                      link={item.link}
                      rank={index}
                      searchItemId={item.searchItemId}
                      submit={submitClickAndNavigate}
                      thumbnailImage={item.thumbnailImage}
                      title={item.title}
                    />
                  ))}
              </div>
            </div>
          </section>
        )}
        {items && totalItems ? (
          <section className="section-gutter section-white">
            <div className="container-fluid">
              <div className="newsroom-list">
                {items &&
                  items.map((item, index) => (
                    <Card
                      altText={item.altText}
                      businessUnitTitle={item.businessUnitTitle}
                      contentType={item.contentType}
                      key={item.link}
                      link={item.link}
                      rank={index}
                      searchItemId={item.searchItemId}
                      submit={submitClickAndNavigate}
                      thumbnailImage={item.thumbnailImage}
                      title={item.title}
                    />
                  ))}
              </div>
              {isShowMoreVisible() && (
                <>
                  <DesktopContainer>
                    <button
                      type="button"
                      className="show-more"
                      onClick={() => {
                        setPage(page + 1);
                        itemsPerPage = 20;
                        getNewsroomData(page + 1, filters, true);
                      }}
                    >
                      {SHOW_MORE}
                    </button>
                  </DesktopContainer>
                  <TabletAndMobileContainer>
                    <button
                      type="button"
                      className="show-more"
                      onClick={() => {
                        setPage(page === 1 ? page + 2 : page + 1);
                        itemsPerPage = 10;
                        getNewsroomData(page === 1 ? page + 2 : page + 1, filters, true);
                      }}
                    >
                      {SHOW_MORE}
                    </button>
                  </TabletAndMobileContainer>
                </>
              )}
            </div>
          </section>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

NewsroomLanding.defaultProps = {
  businessUnitTitle: ''
};

NewsroomLanding.propTypes = {
  businessUnitTitle: PropTypes.string
};

export default NewsroomLanding;
