import React from 'react';
import PropTypes from 'prop-types';
import LazyLoadImg from '../../widgets/shared/LazyLoadImg';

import { sizedImageShape } from '../../../types';

const Tile = ({ altText, image, title, statement, guid, selectItem, isActive }) => {
  return (
    <div className={`tile${isActive ? ' active' : ''}`}>
      <div className="tile-container">
        <button type="button" className="btn-no-style" onClick={() => selectItem(guid)}>
          <LazyLoadImg src={image} alt={altText || `${title} Tile`} />
          <div className={`content${isActive ? ' active' : ''}`}>
            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{ __html: statement }} />
          </div>
        </button>
      </div>
      <span className={`triangle large triangle-no-border${isActive ? ' active' : ''}`} />
    </div>
  );
};

Tile.defaultProps = {
  altText: ''
};

Tile.propTypes = {
  altText: PropTypes.string,
  image: sizedImageShape.isRequired,
  title: PropTypes.string.isRequired,
  statement: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default Tile;
