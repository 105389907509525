import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import LargeHeroSlide from './LargeHeroSlide';
import { largeHeroSlide } from '../../../types';
import DesktopAndTabletContainer from '../../shared/DesktopAndTabletContainer';
import MobileContainer from '../../shared/MobileContainer';

const LargeHeroWidget = ({ autoForwardTiming, heroSlides }) => {
  const sliderSettings = {
    adaptiveHeight: true,
    arrows: false,
    autoplaySpeed: (autoForwardTiming || 0) * 1000,
    autoplay: !!autoForwardTiming,
    dots: true,
    dotsClass: 'vertical-dots',
    lazyLoad: true
  };

  const hasMultipleSlides = heroSlides.length > 1;
  return (
    <>
      {hasMultipleSlides && <h1 className="page-heading">Trustmark Home</h1>}
      <section className="hero hero-large">
        <Slider {...sliderSettings}>
          {heroSlides.map((heroSlide, index) => (
            <Fragment key={heroSlide.title}>
              <MobileContainer>
                <LargeHeroSlide
                  hasHeadingTwo
                  heroSlide={heroSlide}
                  id={index}
                  isOnDesktop={false}
                />
              </MobileContainer>
              <DesktopAndTabletContainer>
                <LargeHeroSlide
                  hasHeadingTwo={hasMultipleSlides}
                  heroSlide={heroSlide}
                  id={index}
                  isOnDesktop
                />
              </DesktopAndTabletContainer>
            </Fragment>
          ))}
        </Slider>
      </section>
    </>
  );
};

LargeHeroWidget.propTypes = {
  autoForwardTiming: PropTypes.number.isRequired,
  heroSlides: PropTypes.arrayOf(largeHeroSlide).isRequired
};

export default LargeHeroWidget;
