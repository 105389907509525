import React from 'react';
import PropTypes from 'prop-types';
import ContentDivider from '../../widgets/shared/ContentDivider';
import DataRow from './DataRow';

const DataTableComponent = ({ rows, showContentDivider }) => {
  return (
    <section>
      <div className="section-gutter">
        {rows.map(row => (
          <DataRow
            description={row.description}
            key={row.guid}
            sections={row.sections}
            title={row.title}
          />
        ))}
      </div>
      {showContentDivider && <ContentDivider />}
    </section>
  );
};

DataTableComponent.defaultProps = {
  rows: [],
  showContentDivider: true
};

DataTableComponent.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  showContentDivider: PropTypes.bool
};

export default DataTableComponent;
