import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '../shared/ContentDivider';

const HtmlSnippetComponent = ({ hasContentDivider, html, prettyUrlTitle }) => {
  return (
    <div className="section section-white">
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
      >
        <div className="container-fluid snippet-section">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {hasContentDivider && <ContentDivider />}
        </div>
      </ConditionalWrapper>
    </div>
  );
};
HtmlSnippetComponent.defaultProps = {
  hasContentDivider: false,
  prettyUrlTitle: ''
};

HtmlSnippetComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  html: PropTypes.string.isRequired,
  prettyUrlTitle: PropTypes.string
};

export default HtmlSnippetComponent;
