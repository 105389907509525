import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';

import {
  AUDIENCE_DROPDOWN_TITLE,
  BU_ALL_OPTION,
  BU_DROPDOWN_TITLE,
  CORPORATE
} from '../../constants/contact-us';

const ContactUsFilters = ({
  filters,
  getAudienceData,
  initialAudienceFilter,
  initialBusinessUnitFilter
}) => {
  const businessUnits = filters;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isBUAccordionExpanded, setBUAccordionIsExpanded] = useState(false);
  const [isAudienceAccordionExpanded, setAudienceAccordionIsExpanded] = useState(false);
  const [selectedBUFilter, setSelectedBUFilter] = useState(CORPORATE);
  const [selectedAudience, setSelectedAudience] = useState(null);

  const toggleBUFilters = () => {
    setBUAccordionIsExpanded(!isBUAccordionExpanded);
  };

  const toggleAudienceFilters = () => {
    setAudienceAccordionIsExpanded(!isAudienceAccordionExpanded);
  };

  const setUrl = (businessUnit, audience) => {
    let currentUrl = window.location.href;

    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
    }

    if (!businessUnit && !audience) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, 'Filter Update', `${currentUrl}`);
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        'Filter Update',
        `${currentUrl}?bu=${businessUnit}&audience=${audience}`
      );
    }
  };

  const setBusinessUnitFilter = (businessUnit, initialAudience, skipUrl) => {
    toggleBUFilters();
    setSelectedBUFilter(businessUnit);

    if (businessUnit === CORPORATE) {
      getAudienceData(null, null);
      setUrl(null, null);
      return;
    }

    let audience = '';

    if (initialAudience && businessUnit !== CORPORATE) {
      audience = initialAudience;
    } else {
      audience = businessUnit.audiences[0].toString();
    }

    setSelectedAudience(audience);
    getAudienceData(businessUnit.key, audience);

    if (!skipUrl) {
      setUrl(businessUnit.key, audience);
    }
  };

  const setAudienceFilter = audience => {
    toggleAudienceFilters();
    setSelectedAudience(audience);
    getAudienceData(selectedBUFilter.key, audience);
    setUrl(selectedBUFilter.key, audience);
  };

  const initializeBUFilter = () => {
    if (initialBusinessUnitFilter) {
      setBusinessUnitFilter(
        businessUnits.find(
          bu => bu.key.toLowerCase() === initialBusinessUnitFilter.toLowerCase()
        ) || CORPORATE,
        initialAudienceFilter,
        true
      );
    } else {
      setSelectedBUFilter(CORPORATE);
    }

    setBUAccordionIsExpanded(false);
  };

  if (isFirstLoad) {
    setIsFirstLoad(false);
    initializeBUFilter();
  }

  return (
    <section className="util">
      <div className="filters">
        <div className="container-fluid">
          <div className="filters-container border-top">
            <div className="business-unit-list br-lg">
              {businessUnits && businessUnits.length > 0 && (
                <ul>
                  <li>
                    <button
                      type="button"
                      className={`btn-filter ${selectedBUFilter === CORPORATE ? 'active' : ''}`}
                      onClick={() => setBusinessUnitFilter(CORPORATE)}
                    >
                      {CORPORATE}
                    </button>
                  </li>
                  {businessUnits.map(businessUnit => (
                    <li key={businessUnit.key}>
                      <button
                        type="button"
                        className={`btn-filter ${
                          selectedBUFilter === businessUnit ? 'active' : ''
                        }`}
                        onClick={() => setBusinessUnitFilter(businessUnit)}
                      >
                        {businessUnit.title}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="accordion-list br-sm">
              <p>{BU_DROPDOWN_TITLE}</p>
              <ul className="accordion">
                <li className={`${isBUAccordionExpanded ? ' active' : ''}`}>
                  <button className="btn-no-style" type="button" onClick={toggleBUFilters}>
                    <span className="btn-contain">
                      {selectedBUFilter === CORPORATE ? BU_ALL_OPTION : selectedBUFilter.title}
                    </span>
                    <img
                      src={isBUAccordionExpanded ? arrowUp : arrowDown}
                      className="chevron"
                      alt="Chevron Arrow"
                    />
                  </button>
                </li>
              </ul>
              <AnimateHeight
                duration={500}
                height={isBUAccordionExpanded ? 'auto' : 0}
                animateOpacity
                className="expanded-filters-list"
              >
                <div className="filters-list">
                  <div className="container-fluid">
                    {businessUnits && businessUnits.length > 0 && (
                      <ul>
                        <li className="block">
                          <button
                            type="button"
                            className="btn-filter"
                            onClick={() => setBusinessUnitFilter(CORPORATE)}
                          >
                            {CORPORATE}
                          </button>
                        </li>
                        {businessUnits.map(businessUnit => (
                          <li key={businessUnit.key} className="block">
                            <button
                              type="button"
                              className="btn-filter"
                              onClick={() => setBusinessUnitFilter(businessUnit)}
                            >
                              {businessUnit.title}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </AnimateHeight>
              {selectedBUFilter !== CORPORATE && (
                <>
                  <p>{AUDIENCE_DROPDOWN_TITLE}</p>
                  <ul className="accordion">
                    <li className={`${isAudienceAccordionExpanded ? ' active' : ''}`}>
                      <button
                        className="btn-no-style"
                        type="button"
                        onClick={toggleAudienceFilters}
                      >
                        <span className="btn-contain">{selectedAudience}</span>
                        <img
                          src={isAudienceAccordionExpanded ? arrowUp : arrowDown}
                          className="chevron"
                          alt="Chevron Arrow"
                        />
                      </button>
                    </li>
                  </ul>
                  <AnimateHeight
                    duration={500}
                    height={isAudienceAccordionExpanded ? 'auto' : 0}
                    animateOpacity
                    className="expanded-filters-list"
                  >
                    <div className="filters-list">
                      <div className="container-fluid">
                        <ul>
                          {selectedBUFilter.audiences.map(audience => (
                            <li key={audience} className="block">
                              <button
                                type="button"
                                className="btn-filter"
                                onClick={() => setAudienceFilter(audience)}
                              >
                                {audience}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </AnimateHeight>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedBUFilter !== CORPORATE && (
        <div className="br-lg">
          <div className="container-fluid">
            <div className="section-gutter-half">
              <div className="sub-filters">
                {selectedBUFilter.audiences.map(audience => (
                  <div
                    className={`tab ${
                      selectedAudience.toLowerCase() === audience.toLowerCase() ? 'active' : ''
                    }`}
                    key={audience}
                  >
                    <button
                      type="button"
                      className="btn-no-style"
                      onClick={() => setAudienceFilter(audience)}
                    >
                      {audience}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ContactUsFilters.defaultProps = {
  filters: [{}],
  initialAudienceFilter: '',
  initialBusinessUnitFilter: ''
};

ContactUsFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  getAudienceData: PropTypes.func.isRequired,
  initialAudienceFilter: PropTypes.string,
  initialBusinessUnitFilter: PropTypes.string
};

export default ContactUsFilters;
