import { apiGetService, apiPostService } from './baseApi.service';

function getSearchSuggestionsData(
  searchText,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false,
  microSitePath
) {
  return (async () => {
    return apiGetService(
      `/api/search/suggest?searchText=${searchText}&useHighlights=${useHighlights}&top=${top}&useFuzzyMatching=${useFuzzyMatching}&microSitePath=${microSitePath}`
    );
  })();
}

export function getSearchSuggestions(
  minimumCharacters,
  searchText,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false,
  microSitePath
) {
  if (searchText.length < minimumCharacters) {
    return null;
  }

  return getSearchSuggestionsData(searchText, useHighlights, top, useFuzzyMatching, microSitePath);
}

export function getSearch(query, page, itemsPerPage, businessUnitTitle, filter, microSitePath) {
  return (async () => {
    let url = `/api/search/search?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query) {
      url += `&query=${query}`;
    }

    if (businessUnitTitle) {
      url += `&businessUnitTitle=${businessUnitTitle}`;
    }

    if (filter) {
      url += `&${filter.key}=${filter.value}`;
    }
    if (microSitePath) {
      url += `&microSitePath=${microSitePath}`;
    }
    return apiGetService(url);
  })();
}

export function submitClick(item) {
  return (async () => {
    return apiPostService('/api/search/submitClick', item);
  })();
}
