import React from 'react';
import PropTypes from 'prop-types';

const SitemapListItem = ({ url, name }) => (
  <li>
    <a href={url}>{name}</a>
  </li>
);

SitemapListItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default SitemapListItem;
