import React from 'react';
import PropTypes from 'prop-types';

import { sizedImageShape } from '../../../types';

const LazyLoadImg = ({ alt, className, id, src, style }) => (
  <img
    alt={alt}
    className={className}
    id={id}
    src={src.url}
    height={src.height}
    width={src.width}
    loading="lazy"
    style={style}
  />
);

LazyLoadImg.defaultProps = {
  alt: '',
  className: undefined,
  id: undefined,
  style: undefined
};

LazyLoadImg.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: sizedImageShape.isRequired,
  style: PropTypes.string
};

export default LazyLoadImg;
