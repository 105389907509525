import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import storeProvider from '../shared/StoreProvider';
import DesktopContainer from '../shared/DesktopContainer';
import TabletAndMobileContainer from '../shared/TabletAndMobileContainer';
import DesktopNavigationMenu from './DesktopNavigationMenu';
import MobileNavigationMenu from './MobileNavigationMenu';
import { globalImage } from '../../types';

const NavigationMenu = ({
  alerts,
  copyright,
  corporateAddress,
  currentUrl,
  footerMenuItems,
  globalImages,
  hasStickyNav,
  isScrollPastNav,
  legalMenuItems,
  mainMenuItems,
  searchUrl,
  isWhiteLabel
}) => {
  const hasAlerts = () => {
    return alerts && !!alerts.length;
  };

  return (
    <>
      <TabletAndMobileContainer>
        <MobileNavigationMenu
          alerts={alerts}
          copyright={copyright}
          corporateAddress={corporateAddress}
          footerMenuItems={footerMenuItems}
          globalImages={globalImages}
          hasAlerts={hasAlerts()}
          hasStickyNav={hasStickyNav}
          isScrollPastNav={isScrollPastNav}
          legalMenuItems={legalMenuItems}
          mainMenuItems={mainMenuItems}
          searchUrl={searchUrl}
          isWhiteLabel={isWhiteLabel}
        />
      </TabletAndMobileContainer>
      <DesktopContainer>
        <DesktopNavigationMenu
          alerts={alerts}
          currentUrl={currentUrl}
          globalImages={globalImages}
          hasAlerts={hasAlerts()}
          isScrollPastNav={isScrollPastNav}
          mainMenuItems={mainMenuItems}
          hasStickyNav={hasStickyNav}
          searchUrl={searchUrl}
          isWhiteLabel={isWhiteLabel}
        />
      </DesktopContainer>
    </>
  );
};

NavigationMenu.defaultProps = {
  alerts: [],
  searchUrl: ''
};

NavigationMenu.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string.isRequired,
  corporateAddress: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  footerMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hasStickyNav: PropTypes.bool.isRequired,
  isScrollPastNav: PropTypes.bool.isRequired,
  legalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchUrl: PropTypes.string,
  isWhiteLabel: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    isScrollPastNav: !!(state.general && state.general.isScrollPastNav)
  };
}

export default storeProvider(connect(mapStateToProps)(NavigationMenu));
