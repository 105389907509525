import React from 'react';
import PropTypes from 'prop-types';

const TextComponent = ({ text }) => {
  return (
    <section className="section-white sub-section">
      <div className="container-fluid" dangerouslySetInnerHTML={{ __html: text }} />
    </section>
  );
};

TextComponent.propTypes = {
  text: PropTypes.string.isRequired
};

export default TextComponent;
