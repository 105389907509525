import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import getQueryStringParameters from '../../../services/queryString.service';
import { scrollToParameter, getAllFAQButtons } from '../../../services/scroller.service';

const Scroller = ({ hasAlerts }) => {
  useEffect(() => {
    getQueryStringParameters().then(queryStringParameters => {
      Object.keys(queryStringParameters).forEach(key => {
        scrollToParameter(queryStringParameters[key], hasAlerts, key);
      });
    });

    getAllFAQButtons(hasAlerts);
  });

  return <span />;
};

Scroller.defaultProps = {
  hasAlerts: []
};

Scroller.propTypes = {
  hasAlerts: PropTypes.bool
};

export default Scroller;
