import { isRunningOnServer } from '../utilities';
import {
  setIsScrollPastNavAction,
  setIsScrollPastFooterAction
} from '../state/actions/general.actions';

const initializeSite = dispatch => {
  if (isRunningOnServer()) {
    return;
  }

  const setIsScrollPastNav = () => {
    const isScrollPastNav = !!(
      document.body.scrollTop > 70 || document.documentElement.scrollTop > 70
    );

    dispatch(setIsScrollPastNavAction(isScrollPastNav));
  };

  const setIsScrollPastFooter = () => {
    const height = window.innerHeight;
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const elemFooter = document.getElementById('footer');
    if (elemFooter !== null && elemFooter !== 'undefined') {
      const footerTop = document.getElementById('footer').offsetTop;
      const viewportBottom = scrollTop + height;
      const isScrollPastFooter = !!(footerTop < viewportBottom);
      dispatch(setIsScrollPastFooterAction(isScrollPastFooter));
    }
  };

  window.onscroll = () => {
    setIsScrollPastNav();
    setIsScrollPastFooter();
  };

  window.onload = () => {
    setIsScrollPastNav();
    setIsScrollPastFooter();
  };
};

export default initializeSite;
