import React, { useState } from 'react';
import { Element } from 'react-scroll';

import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

import FaqSecondary from './FaqSecondary';

const Faq = ({ answer, faqs, prettyUrlTitle, question, upArrow, downArrow }) => {
  const [isFaqVisible, setIsFaqVisible] = useState(false);

  const toggleFaq = () => {
    setIsFaqVisible(!isFaqVisible);
  };
  return (
    <div className={`faq primary ${isFaqVisible ? 'open' : 'close'}`}>
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={children => (
          <Element name={prettyUrlTitle} id={prettyUrlTitle} className="anchor-wrapper">
            {children}
          </Element>
        )}
      >
        <button className="btn-no-style" type="button" onClick={toggleFaq}>
          {question}
          {isFaqVisible && <img src={upArrow} className="icon" alt="Chevron Arrow" />}
          {!isFaqVisible && <img src={downArrow} className="icon" alt="Chevron Arrow" />}
        </button>
      </ConditionalWrapper>
      <AnimateHeight duration={500} height={isFaqVisible ? 'auto' : 0}>
        {answer && <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />}
        {!!faqs &&
          faqs.map(faq => (
            <FaqSecondary
              answer={faq.answer}
              key={faq.question}
              prettyUrlTitle={faq.prettyUrlTitle}
              question={faq.question}
            />
          ))}
      </AnimateHeight>
    </div>
  );
};

Faq.defaultProps = {
  answer: '',
  faqs: [],
  prettyUrlTitle: '',
  upArrow: '',
  downArrow: ''
};

Faq.propTypes = {
  answer: PropTypes.string,
  faqs: PropTypes.arrayOf(PropTypes.object),
  prettyUrlTitle: PropTypes.string,
  question: PropTypes.string.isRequired,
  upArrow: PropTypes.string,
  downArrow: PropTypes.string
};

export default Faq;
