import React from 'react';
import PropTypes from 'prop-types';

import AnchorWrapper from './AnchorWrapper';

const LegalMenuItem = ({ name, target, url }) => {
  return (
    <AnchorWrapper url={url} target={target} name={name}>
      <>{name}</>
    </AnchorWrapper>
  );
};

LegalMenuItem.defaultProps = {
  name: '',
  target: '',
  url: ''
};

LegalMenuItem.propTypes = {
  name: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string
};

export default LegalMenuItem;
