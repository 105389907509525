import { apiGetService, apiPostService } from './baseApi.service';

function getSearchSuggestionsData(
  searchText,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false
) {
  return (async () => {
    return apiGetService(
      `/api/careers/suggest?searchText=${searchText}&useHighlights=${useHighlights}&top=${top}&useFuzzyMatching=${useFuzzyMatching}`
    );
  })();
}

export function getSearchSuggestions(
  minimumCharacters,
  searchText,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false
) {
  if (searchText.length < minimumCharacters) {
    return null;
  }

  return getSearchSuggestionsData(searchText, useHighlights, top, useFuzzyMatching);
}

export function careersSearch(query, filters, page, itemsPerPage, zipRange, zip) {
  let filterString;
  if (filters) {
    filterString = filters
      .map(filter => `${filter.key}=${encodeURIComponent(filter.values.join(','))}`)
      .join('&');
  }

  return (async () => {
    let url = `/api/careers/getCareers?query=${query}&page=${page}&itemsPerPage=${itemsPerPage}`;

    if (filterString) {
      url += `&${filterString}`;
    }

    if (zip && zip.length === 5 && zipRange !== 0) {
      url += `&zipRange=${zipRange}&zip=${zip}`;
    }

    return apiGetService(url);
  })();
}

export function submitClick(item) {
  return (async () => {
    return apiPostService('/api/careers/submitClick', item);
  })();
}
