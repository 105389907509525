import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentDivider from '../widgets/shared/ContentDivider';
import StateSelector from './StateSelector';
import { searchHeaderShape } from '../../types';

const StateSelectorComponent = ({
  initialState,
  searchHeader,
  states,
  showContentDividerInitialState,
  showDataTableContentDivider
}) => {
  const [showContentDivider, setShowContentDivider] = useState(showContentDividerInitialState);
  return (
    <>
      {states && (
        <div className="container-fluid">
          <div className="section-gutter">
            <StateSelector
              {...{
                initialState,
                searchHeader,
                states,
                setShowContentDivider,
                showDataTableContentDivider
              }}
            />
          </div>
          {showContentDivider && <ContentDivider />}
        </div>
      )}
    </>
  );
};

StateSelectorComponent.defaultProps = {
  initialState: '',
  showContentDividerInitialState: true,
  showDataTableContentDivider: true
};

StateSelectorComponent.propTypes = {
  initialState: PropTypes.string,
  searchHeader: searchHeaderShape.isRequired,
  showContentDividerInitialState: PropTypes.bool,
  showDataTableContentDivider: PropTypes.bool,
  states: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default StateSelectorComponent;
