import React from 'react';
import PropTypes from 'prop-types';

import close from '../../../images/icons/layout/Close-Blue.svg';
import { OVERVIEW, LEARN_MORE_LINK, NEWSROOM_READ_MORE } from '../../../constants/expansion-titles';
import { newsroomInfoShape } from '../../../types';
import LazyLoadImg from '../shared/LazyLoadImg';

const BusinessUnitTileExpansion = ({
  overviewIcon,
  overview,
  learnMoreLink,
  learnMoreTarget,
  resourceLinksIcon,
  resourceLinksTitle,
  resourceLinks,
  newsroomLink,
  newsroomInfo,
  selectBusinessUnit
}) => {
  return (
    <>
      <button type="button" onClick={() => selectBusinessUnit()} className="close-button">
        <img className="small-icon" src={close} alt="Close" />
      </button>
      <div className="tile-expansion">
        <div className="col-8 no-padding">
          <div className="heading">
            {overviewIcon && <img className="small-icon" src={overviewIcon} alt="Overview Icon" />}
            <h3>{OVERVIEW}</h3>
          </div>
          <div className="inner-content" dangerouslySetInnerHTML={{ __html: overview }} />
          <a
            href={learnMoreLink}
            {...learnMoreTarget && { target: learnMoreTarget }}
            title="Learn More"
          >
            {LEARN_MORE_LINK}
          </a>
          <div className="heading">
            {resourceLinksIcon && <img src={resourceLinksIcon} alt="resourceLinks Icon" />}
            <h3>{resourceLinksTitle}</h3>
          </div>
          {resourceLinks &&
            resourceLinks.map(link => (
              <a key={link.name} target={link.target} href={link.url} title={`Go to ${link.name}`}>
                {link.name}
              </a>
            ))}
        </div>
        <div className="col-4 small-column no-padding">
          {newsroomInfo && (
            <>
              <h3 className="heading">{newsroomInfo.contentType}</h3>
              <a className="newsroom-link" href={newsroomLink} title="Newsroom Link">
                <LazyLoadImg src={newsroomInfo.thumbnailImage} alt="Newsroom Article" />
              </a>
              <h4 className="newsroom-title">{newsroomInfo.title}</h4>
              <a className="newsroom-link" href={newsroomLink} title="Newsroom Link">
                {NEWSROOM_READ_MORE}
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
};

BusinessUnitTileExpansion.defaultProps = {
  overviewIcon: '',
  resourceLinksIcon: '',
  resourceLinksTitle: '',
  resourceLinks: [],
  newsroomLink: '',
  newsroomInfo: {}
};

BusinessUnitTileExpansion.propTypes = {
  overviewIcon: PropTypes.string,
  overview: PropTypes.string.isRequired,
  learnMoreLink: PropTypes.string.isRequired,
  learnMoreTarget: PropTypes.string.isRequired,
  resourceLinksIcon: PropTypes.string,
  resourceLinksTitle: PropTypes.string,
  resourceLinks: PropTypes.arrayOf(PropTypes.object),
  newsroomLink: PropTypes.string,
  selectBusinessUnit: PropTypes.func.isRequired,
  newsroomInfo: newsroomInfoShape
};

export default BusinessUnitTileExpansion;
