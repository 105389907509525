import React from 'react';
import PropTypes from 'prop-types';
import { businessUnit } from '../../../types';

const BusinessUnitButton = ({ businessUnitDetails, isActive, selectBusinessUnit }) => {
  return (
    <div className="business-unit-container">
      <button
        className={isActive ? 'active' : ''}
        type="button"
        onClick={() => selectBusinessUnit(businessUnitDetails)}
      >
        {businessUnitDetails.title}
      </button>
      <span className={`triangle triangle-no-border${isActive ? ' active' : ''}`} />
    </div>
  );
};

BusinessUnitButton.defaultProps = {
  isActive: false
};

BusinessUnitButton.propTypes = {
  businessUnitDetails: businessUnit.isRequired,
  isActive: PropTypes.bool,
  selectBusinessUnit: PropTypes.func.isRequired
};

export default BusinessUnitButton;
