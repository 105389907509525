import React from 'react';
import PropTypes from 'prop-types';

const GlobalComponentContainer = ({ parentPageTitle, parentPageUrl, shortDescription, title }) => {
  return (
    <div className="global-props">
      {parentPageUrl && (
        <div className="parent-page-link">
          <a href={parentPageUrl} title="Parent Page">
            {parentPageTitle}
          </a>
        </div>
      )}
      {title && <h2>{title}</h2>}
      {shortDescription && <div dangerouslySetInnerHTML={{ __html: shortDescription }} />}
    </div>
  );
};

GlobalComponentContainer.defaultProps = {
  parentPageTitle: null,
  parentPageUrl: null,
  shortDescription: null,
  title: null
};

GlobalComponentContainer.propTypes = {
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string
};

export default GlobalComponentContainer;
