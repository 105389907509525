import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DateFormat from 'dateformat';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import ContentDivider from '../../widgets/shared/ContentDivider';
import { authorShape } from '../../../types';
import {
  AUTHOR_TITLE,
  FACEBOOK_IMAGE,
  LINKEDIN_IMAGE,
  SHARE_TITLE,
  TOPICS_TITLE,
  TWITTER_IMAGE
} from '../../../constants/newsroom';
import { useCssVariables } from '../../../utilities';

const NewsroomDetailContent = ({
  author,
  mainContent,
  path,
  publicationDate,
  topics,
  globalNewsroomPath,
  shouldHideDivider
}) => {
  const date = new Date(publicationDate);
  const newDate = DateFormat(date, 'mmmm dS, yyyy');

  const facebookRef = useRef(null);
  const twitterRef = useRef(null);
  const linkedInRef = useRef(null);

  useCssVariables(facebookRef, { '--background-image': `url('${FACEBOOK_IMAGE}')` });
  useCssVariables(twitterRef, { '--background-image': `url('${TWITTER_IMAGE}')` });
  useCssVariables(linkedInRef, { '--background-image': `url('${LINKEDIN_IMAGE}')` });

  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="content section-gutter">
          <div className="main-content">
            {author && (
              <p className="published-by">{`Published by ${author.name} on ${newDate}`}</p>
            )}
            <div className="inner-content" dangerouslySetInnerHTML={{ __html: mainContent }} />
          </div>
          <div className="sidebar">
            <div className="section">
              <p className="section-header">{SHARE_TITLE}</p>
              <div ref={facebookRef} className="share-button-wrapper">
                <FacebookShareButton className="share-button" url={path} />
              </div>
              <div ref={twitterRef} className="share-button-wrapper">
                <TwitterShareButton className="share-button" url={path} />
              </div>
              <div ref={linkedInRef} className="share-button-wrapper">
                <LinkedinShareButton className="share-button" url={path} />
              </div>
            </div>
            {author && (
              <div className="section">
                <p className="section-header">{AUTHOR_TITLE}</p>
                <span className="author">
                  <img src={author.photo} alt={author.altText ? author.altText : author.name} />
                  <p className="published-by">{`Written by ${author.name}`}</p>
                  <p dangerouslySetInnerHTML={{ __html: author.bio }} />
                </span>
              </div>
            )}
            {topics && (
              <div className="section">
                <p className="section-header">{TOPICS_TITLE}</p>
                <span className="tags">
                  {topics.map(topic => (
                    <a
                      href={`${globalNewsroomPath}?${topic.queryStringField}=${topic.displayName}`}
                      key={topic.className}
                      title={`${topic.displayName} Landing Page`}
                    >
                      {topic.displayName}
                    </a>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
        {!shouldHideDivider && <ContentDivider />}
      </div>
    </section>
  );
};

NewsroomDetailContent.defaultProps = {
  globalNewsroomPath: '',
  topics: []
};

NewsroomDetailContent.propTypes = {
  author: authorShape.isRequired,
  globalNewsroomPath: PropTypes.string,
  mainContent: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.object),
  shouldHideDivider: PropTypes.bool.isRequired
};

export default NewsroomDetailContent;
