import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCssVariables } from '../../../utilities';

const CtaButton = ({ children, target, path, textColor, backgroundColor, className }) => {
  const ctaButtonRef = useRef(null);

  useCssVariables(ctaButtonRef, {
    '--cta-button-text-color': `${textColor || 'unset'}`,
    '--cta-button-background-color': `${backgroundColor || 'unset'}`
  });

  return (
    <div className={classNames('btn-container', className)} ref={ctaButtonRef}>
      <a className="btn" href={path} target={target}>
        {children}
      </a>
    </div>
  );
};

CtaButton.defaultProps = {
  children: null,
  className: ''
};

CtaButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
  target: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CtaButton;
