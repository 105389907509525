import React from 'react';
import PropTypes from 'prop-types';
import ParagraphItem from '../shared/ParagraphItem';

const ProductsServicesTileExpansion = ({ isSlider, overview, selectProduct, closeIcon }) => {
  return (
    <>
      <button
        type="button"
        onClick={() => selectProduct()}
        className={isSlider ? 'close-button' : 'close-button no-slider'}
      >
        <img src={closeIcon} alt="Close" />
      </button>
      <div className={isSlider ? 'tile-expansion' : 'tile-expansion no-slider'}>
        <div className="content">
          <ParagraphItem title="" description={overview} />
        </div>
      </div>
    </>
  );
};

ProductsServicesTileExpansion.propTypes = {
  isSlider: PropTypes.bool.isRequired,
  overview: PropTypes.string.isRequired,
  selectProduct: PropTypes.func.isRequired,
  closeIcon: PropTypes.string.isRequired
};

export default ProductsServicesTileExpansion;
