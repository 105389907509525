import { scroller } from 'react-scroll';

export const scrollToParameter = (value, hasAlerts, key) => {
  const anchorSettings = {
    duration: 500,
    delay: 250,
    offset: hasAlerts ? -220 : -140
  };
  const parameter = value;

  // Logic for global component anchor link
  if (key.toLowerCase() === 'section') {
    // Check if element exists
    scroller.scrollTo(parameter, anchorSettings);
  }
  // Logic for faq item anchor link
  if (key.toLowerCase() === 'faq') {
    const faqItem = document.getElementById(parameter);

    // Check if element exists
    if (faqItem !== null) {
      // If faq item anchor is nested then trigger click of primary faq container
      if (faqItem.parentElement.classList.contains('secondary')) {
        const primaryContainer = faqItem.closest('.primary');
        primaryContainer.childNodes[0].click();
      }
      // Scroll to selected faq
      scroller.scrollTo(parameter, anchorSettings);
      // Trigger click of selected faq button to expand
      document.getElementById(parameter).childNodes[0].click();
    }
  }
};

export const getAllFAQButtons = hasAlerts => {
  const list = document.getElementsByClassName('anchor-link');
  for (let i = 0; i < list.length; i += 1) {
    list[i].onclick = () => {
      const value = list[i].getAttribute('faq');
      scrollToParameter(value, hasAlerts, 'faq');
    };
  }
};
