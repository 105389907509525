import React from 'react';
import PropTypes from 'prop-types';

import { pagerShape } from '../../../types';

const Pager = ({ search, searchText, currentPage, pagerInfo }) => {
  const showPrevious = currentPage !== 1;
  const showNext = currentPage !== pagerInfo.totalPages;
  const activePages = [];
  const hide = pagerInfo.totalPages === 1;

  for (let i = pagerInfo.startPage; i <= pagerInfo.endPage; i += 1) {
    if (i > 1 && i < pagerInfo.totalPages) {
      activePages.push(i);
    }
  }

  return (
    <>
      {!hide && (
        <div className="pager">
          {showPrevious && (
            <button
              type="button"
              className="btn-no-style"
              onClick={() => search(searchText, currentPage - 1)}
            >
              Prev
            </button>
          )}
          <button
            type="button"
            className={`btn-no-style ${currentPage === 1 ? 'current-page' : ''}`}
            onClick={() => search(searchText, 1)}
          >
            1
          </button>
          {pagerInfo.startPage > 2 && <span>. . .</span>}
          {activePages.map(page => (
            <button
              type="button"
              key={page}
              className={`btn-no-style ${currentPage === page ? 'current-page' : ''}`}
              onClick={() => search(searchText, page)}
            >
              {page}
            </button>
          ))}
          {pagerInfo.endPage < pagerInfo.totalPages - 1 && <span>. . .</span>}
          <button
            type="button"
            className={`btn-no-style ${currentPage === pagerInfo.totalPages ? 'current-page' : ''}`}
            onClick={() => search(searchText, pagerInfo.totalPages)}
          >
            {pagerInfo.totalPages}
          </button>
          {showNext && (
            <button
              type="button"
              className="btn-no-style"
              onClick={() => search(searchText, currentPage + 1)}
            >
              Next
            </button>
          )}
        </div>
      )}
    </>
  );
};

Pager.defaultProps = {
  currentPage: 0,
  searchText: '',
  pagerInfo: {}
};

Pager.propTypes = {
  currentPage: PropTypes.number,
  search: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  pagerInfo: pagerShape
};

export default Pager;
