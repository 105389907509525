import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { largeHeroSlide } from '../../../types';
import {
  SLIDE_BACKGROUND_COLOR,
  SLIDE_BACKGROUND_IMAGE,
  SLIDE_BACKGROUND_VIDEO
} from '../../../constants/slide-background-types';

import {
  SLIDE_CONTENT_POSITION_CENTER,
  SLIDE_CONTENT_POSITION_LEFT,
  SLIDE_CONTENT_POSITION_RIGHT
} from '../../../constants/slide-content-positions';

import CtaButton from '../../shared/ctaButton/CtaButton';
import { useCssVariables } from '../../../utilities';

const getJustifyContentValue = contentPosition => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'flex-end';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'flex-start';
  }
};

const getTextAlignValue = contentPosition => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'right';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'left';
  }
};

const getBackgroundTypeClass = backgroundType => {
  switch (backgroundType) {
    case SLIDE_BACKGROUND_COLOR:
      return 'color-wrapper';
    case SLIDE_BACKGROUND_VIDEO:
      return 'video-wrapper';
    default:
      return '';
  }
};

const LargeHeroSlide = ({ hasHeadingTwo, heroSlide, isOnDesktop = true }) => {
  const background = isOnDesktop ? heroSlide.desktopBackground : heroSlide.mobileBackground;
  const hasBackgroundColor = background.type === SLIDE_BACKGROUND_COLOR;
  const hasBackgroundImage = background.type === SLIDE_BACKGROUND_IMAGE;
  const hasBackgroundVideo = background.type === SLIDE_BACKGROUND_VIDEO;
  const hasCtaButton = !!heroSlide.ctaButton.hasCtaButton;
  const hasSecondCtaButton = !!heroSlide.ctaButton.hasSecondCtaButton;

  const slideRef = useRef(null);

  useCssVariables(slideRef, {
    '--background-color': `${(hasBackgroundColor ? background.value : 'unset') || 'unset'}`,
    '--text-color': `${heroSlide.textColor || 'unset'}`,
    '--slide-text-color': `${
      !isOnDesktop && heroSlide.mobileTextColor ? heroSlide.mobileTextColor : heroSlide.textColor
    }`,
    '--slide-justify-content': getJustifyContentValue(heroSlide.textPosition),
    '--slide-text-align': getTextAlignValue(heroSlide.contentPosition),
    '--button-justify-content': getJustifyContentValue(heroSlide.ctaButton.position)
  });

  return (
    <div className="item" ref={slideRef}>
      <div
        className="content-overlay"
        style={{ backgroundImage: !isOnDesktop && heroSlide.disableGradientOverlay && 'unset' }}
      />
      {hasBackgroundImage && (
        <picture>
          <img src={background.value} alt={heroSlide.altText || 'Hero Slide'} />
        </picture>
      )}
      <div className={getBackgroundTypeClass(background.type)}>
        {hasBackgroundVideo && (
          <iframe src={background.value} frameBorder="0" allowFullScreen title="Hero Video" />
        )}
        <div className="content">
          <div className="container-fluid slide-content">
            <div className="inner-content">
              {hasHeadingTwo ? (
                <h2>
                  <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
                </h2>
              ) : (
                <h1>
                  <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
                </h1>
              )}
              <p>{heroSlide.shortDescription}</p>
              {hasCtaButton && (
                <div className="button-wrapper">
                  <CtaButton
                    backgroundColor={heroSlide.ctaButton.backgroundColor}
                    textColor={heroSlide.ctaButton.textColor}
                    path={heroSlide.ctaButton.path}
                    target={heroSlide.ctaButton.target}
                  >
                    {heroSlide.ctaButton.text}
                  </CtaButton>
                  {hasSecondCtaButton && (
                    <CtaButton
                      backgroundColor={heroSlide.ctaButton.secondBackgroundColor}
                      textColor={heroSlide.ctaButton.secondTextColor}
                      path={heroSlide.ctaButton.secondPath}
                      target={heroSlide.ctaButton.secondTarget}
                    >
                      {heroSlide.ctaButton.secondText}
                    </CtaButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LargeHeroSlide.defaultProps = {
  hasHeadingTwo: false,
  isOnDesktop: true
};

LargeHeroSlide.propTypes = {
  hasHeadingTwo: PropTypes.bool,
  heroSlide: largeHeroSlide.isRequired,
  isOnDesktop: PropTypes.bool
};

export default LargeHeroSlide;
