export const AUTHOR_TITLE = 'Author';
export const FEATURED_TITLE = 'Featured';
export const SHARE_TITLE = 'Share';
export const TOPICS_TITLE = 'Topics';

export const FACEBOOK_IMAGE =
  '/Trustmark-Benefits-Web/media/Social-Media-Icons/social-facebook-dark.png?ext=.png';
export const LINKEDIN_IMAGE =
  '/Trustmark-Benefits-Web/media/Social-Media-Icons/social-linkedin-dark.png?ext=.png';
export const TWITTER_IMAGE =
  '/Trustmark-Benefits-Web/media/Social-Media-Icons/social-twitter-dark.png?ext=.png';

export const ALL = 'All';
export const SHOW_MORE = 'Show More';

export const ALL_TRUSTMARK = 'All Trustmark';
export const ALL_ITEMS = 'All Items';
