import React from 'react';
import PropTypes from 'prop-types';

const SelectItem = ({ title, value }) => {
  return <option value={value}>{title}</option>;
};

SelectItem.propTypes = { title: PropTypes.string.isRequired, value: PropTypes.string.isRequired };

export default SelectItem;
