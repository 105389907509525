import fetch from 'cross-fetch';

export function apiGetService(url) {
  return (async () => {
    const response = await fetch(url);
    const json = await response.json();

    return json.data;
  })();
}

export function apiPostService(url, body) {
  return (async () => {
    try {
      await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
    } catch (error) {
      // console.log(error);
    }
  })();
}
