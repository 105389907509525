import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '../shared/ContentDivider';

const LegalDisclaimerComponent = ({ legalDisclaimer, prettyUrlTitle, hasContentDivider }) => {
  return (
    <section className="section-white">
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
      >
        <div className="container-fluid">
          <div className="section-legal">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: legalDisclaimer }}
              suppressHydrationWarning
            />
          </div>
          {hasContentDivider && <ContentDivider />}
        </div>
      </ConditionalWrapper>
    </section>
  );
};

LegalDisclaimerComponent.defaultProps = {
  hasContentDivider: false,
  prettyUrlTitle: ''
};

LegalDisclaimerComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  legalDisclaimer: PropTypes.string.isRequired,
  prettyUrlTitle: PropTypes.string
};

export default LegalDisclaimerComponent;
