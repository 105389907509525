import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ParagraphItem from '../shared/ParagraphItem';

const ProductsServicesModalExpansion = ({
  isActive,
  overview,
  selectProduct,
  shortDescription,
  title,
  closeIcon
}) => {
  ReactModal.setAppElement('#wrapper');

  return (
    <ReactModal
      isOpen={isActive}
      className="mobile-modal"
      overlayClassName="mobile-modal-overlay br-sm"
      bodyOpenClassName="ReactModal__Body--open-desktop-scroll"
    >
      <button type="button" onClick={() => selectProduct()} className="close-button">
        <img src={closeIcon} alt="Close" />
      </button>
      <div className="tile">
        <div className="content active">
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: shortDescription }} />
        </div>
        <span className="triangle large triangle-no-border active" />
        <div className="tile-expansion">
          <ParagraphItem title="" description={overview} />
        </div>
      </div>
    </ReactModal>
  );
};

ProductsServicesModalExpansion.defaultProps = {
  isActive: false,
  overview: '',
  shortDescription: '',
  title: '',
  closeIcon: ''
};

ProductsServicesModalExpansion.propTypes = {
  isActive: PropTypes.bool,
  overview: PropTypes.string,
  selectProduct: PropTypes.func.isRequired,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  closeIcon: PropTypes.string
};

export default ProductsServicesModalExpansion;
