import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactUsGlobalComponent from './ContactUsGlobalComponent';
import ContactUsFilters from './ContactUsFilters';
import { searchHeaderShape } from '../../types';
import ContactUsAudienceComponent from './ContactUsAudienceComponent';
import contactUsSearch from '../../services/contactUsSearch.service';
import Loader from '../shared/loader/Loader';

const ContactUsLanding = ({
  businessUnitFilters,
  hideStateSelector,
  initialAudienceFilter,
  initialBusinessUnitFilter,
  initialState,
  rows,
  searchHeader,
  states
}) => {
  const [audienceData, setAudienceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAudienceData = async (businessUnit, audience) => {
    if (businessUnit === null && audience === null) {
      setAudienceData(null);
      return;
    }

    setIsLoading(true);
    const response = await contactUsSearch(businessUnit, audience);

    if (response) {
      setAudienceData(response);
    }

    setIsLoading(false);
  };

  return (
    <div className="section-white">
      {isLoading && <Loader />}
      <ContactUsFilters
        filters={businessUnitFilters}
        getAudienceData={getAudienceData}
        initialAudienceFilter={initialAudienceFilter}
        initialBusinessUnitFilter={initialBusinessUnitFilter}
      />
      {!audienceData && (
        <ContactUsGlobalComponent
          {...{ rows, searchHeader, states, initialState, hideStateSelector }}
        />
      )}
      {audienceData && <ContactUsAudienceComponent {...{ ...audienceData, initialState }} />}
    </div>
  );
};

ContactUsLanding.defaultProps = {
  initialAudienceFilter: '',
  initialBusinessUnitFilter: '',
  initialState: '',
  rows: [],
  searchHeader: {}
};

ContactUsLanding.propTypes = {
  businessUnitFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideStateSelector: PropTypes.bool.isRequired,
  initialAudienceFilter: PropTypes.string,
  initialBusinessUnitFilter: PropTypes.string,
  initialState: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ContactUsLanding;
