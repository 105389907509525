import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { scroller, Element } from 'react-scroll';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';

import PlayIcon from '../shared/PlayIcon';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '../shared/ContentDivider';
import VideoModal from '../shared/VideoModal';
import LazyLoadImg from '../shared/LazyLoadImg';
import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';

import { sizedImageShape, animationShape } from '../../../types';

const IntroComponent = ({
  altText,
  hasAlerts,
  image,
  imageUrl,
  imageUrlTarget,
  jumpLinks,
  playIconColor,
  prettyUrlTitle,
  text,
  title,
  resourceLinks,
  hasContentDivider,
  videoUrl,
  animation
}) => {
  const hasAnimation = animation && animation.animationUrl;
  const hasImage = image && image.url;
  const hasMedia = hasAnimation || hasImage;
  const hasJumpLinks = !!(jumpLinks && jumpLinks.length);
  const hasResourceLinks = !!(resourceLinks && resourceLinks.length);
  const hasLinks = hasJumpLinks || hasResourceLinks;
  const topResourceLinks = resourceLinks.slice(0, 5);
  const [modalState, setModalState] = useState(false);

  const anchorSettings = {
    duration: 500,
    delay: 150,
    smooth: true,
    offset: hasAlerts ? -220 : -140
  };

  const scrollToSection = section => {
    scroller.scrollTo(section, anchorSettings);
  };

  ReactModal.setAppElement('#wrapper');
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <div className="section-intro">
              <div className="content">
                <h2>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
              <div className={classNames('content-right', !hasAnimation && 'media')}>
                <ConditionalWrapper
                  condition={videoUrl}
                  wrapper={children => (
                    <button
                      className="btn-no-style"
                      type="button"
                      onClick={() => setModalState(true)}
                    >
                      {children}
                    </button>
                  )}
                >
                  {videoUrl && <PlayIcon iconColor={playIconColor} />}

                  {hasMedia ? (
                    hasImage ? (
                      <ConditionalWrapper
                        condition={imageUrl && !videoUrl}
                        wrapper={children => (
                          <a
                            href={imageUrl}
                            target={imageUrlTarget}
                            title={`Link for ${altText || title}`}
                          >
                            {children}
                          </a>
                        )}
                      >
                        <LazyLoadImg src={image} alt={altText || title} />
                      </ConditionalWrapper>
                    ) : (
                      <>
                        <DesktopContainer>
                          <Player
                            src={animation.animationUrl}
                            background={animation.backgroundColor}
                            speed={animation.animationSpeed}
                            style={{
                              width: animation.width,
                              height: animation.height
                            }}
                            loop={animation.loop}
                            autoplay={animation.autoplay}
                            hover={animation.hover}
                            mode={animation.playMode}
                          />
                        </DesktopContainer>
                        <TabletAndMobileContainer>
                          <Player
                            src={animation.animationUrl}
                            background={animation.backgroundColor}
                            speed={animation.animationSpeed}
                            style={{
                              width: animation.deviceWidth,
                              height: animation.deviceHeight
                            }}
                            loop={animation.loop}
                            autoplay={animation.autoplay}
                            hover={animation.hover}
                            mode={animation.playMode}
                          />
                        </TabletAndMobileContainer>
                      </>
                    )
                  ) : (
                    hasLinks && (
                      <ul className="quick-links">
                        <li>
                          <span className="menu-header">Quick Links</span>
                        </li>
                        {hasJumpLinks &&
                          jumpLinks.map(link => (
                            <li key={link.title}>
                              <button
                                onClick={() => {
                                  scrollToSection(link.section);
                                }}
                                className="anchor-link"
                                title={`Jump to ${link.title} section`}
                                type="button"
                              >
                                {link.title}
                              </button>
                            </li>
                          ))}
                        {hasResourceLinks &&
                          topResourceLinks.map(link => (
                            <li key={link.name}>
                              <a href={link.url} target={link.target} title={link.name}>
                                {link.name}
                              </a>
                            </li>
                          ))}
                      </ul>
                    )
                  )}
                </ConditionalWrapper>
              </div>
            </div>
          </ConditionalWrapper>
        </div>
        <VideoModal selectFunction={setModalState} isActive={modalState} videoUrl={videoUrl} />
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

IntroComponent.defaultProps = {
  altText: '',
  hasContentDivider: false,
  image: {},
  imageUrl: '',
  imageUrlTarget: '',
  jumpLinks: [],
  playIconColor: '#FFFFFF',
  prettyUrlTitle: '',
  resourceLinks: [],
  videoUrl: '',
  animation: {}
};

IntroComponent.propTypes = {
  altText: PropTypes.string,
  hasAlerts: PropTypes.bool.isRequired,
  hasContentDivider: PropTypes.bool,
  image: sizedImageShape,
  imageUrl: PropTypes.string,
  imageUrlTarget: PropTypes.string,
  jumpLinks: PropTypes.arrayOf(PropTypes.object),
  playIconColor: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  resourceLinks: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string,
  animation: animationShape
};

export default IntroComponent;
