import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { animationShape } from '../../../types';
import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';

const AnimationComponent = ({ animation }) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="section-gutter">
          <div className="section-animation">
            <>
              <DesktopContainer>
                <Player
                  src={animation.animationUrl}
                  background={animation.backgroundColor}
                  speed={animation.animationSpeed}
                  style={{
                    width: animation.width,
                    height: animation.height
                  }}
                  loop={animation.loop}
                  autoplay={animation.autoplay}
                  hover={animation.hover}
                  mode={animation.playMode}
                />
              </DesktopContainer>
              <TabletAndMobileContainer>
                <Player
                  src={animation.animationUrl}
                  background={animation.backgroundColor}
                  speed={animation.animationSpeed}
                  style={{
                    width: animation.deviceWidth,
                    height: animation.deviceHeight
                  }}
                  loop={animation.loop}
                  autoplay={animation.autoplay}
                  hover={animation.hover}
                  mode={animation.playMode}
                />
              </TabletAndMobileContainer>
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

AnimationComponent.defaultProps = {
  animation: {}
};

AnimationComponent.propTypes = {
  animation: animationShape
};

export default AnimationComponent;
