import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { isRunningOnServer } from '../../../utilities';
import BusinessUnitDetail from './BusinessUnitDetail';
import BusinessUnitButton from './BusinessUnitButton';

const DesktopMenuItem = ({
  businessUnitMenuItems,
  children,
  content,
  currentUrl,
  icon,
  isActive,
  name,
  showMenuItemsAsTiles,
  target,
  url
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
  const hasBusinessUnits = businessUnitMenuItems && !!businessUnitMenuItems.length;
  const hasValidSubLinks = !!(children && children.length);

  const headingHasIcon = !!icon;
  const hasSubMenuItems = hasValidSubLinks || hasBusinessUnits;

  const toggleSubMenu = value => {
    if (hasSubMenuItems) {
      setIsExpanded(value);

      if (!isExpanded) {
        setSelectedBusinessUnit(null);
      }
    }
  };

  const selectBusinessUnit = businessUnit => {
    if (selectedBusinessUnit === businessUnit) {
      setSelectedBusinessUnit(null);
    } else {
      setSelectedBusinessUnit(businessUnit);
    }
  };

  return (
    <li
      onMouseEnter={() => toggleSubMenu(true)}
      onMouseLeave={() => toggleSubMenu(false)}
      className={`br-lg-flx${url === currentUrl || isActive || isExpanded ? ' active' : ''}`}
    >
      <span className="btn-contain">
        {url ? (
          <>
            <a href={url} target={target} title={`Link to ${name}`}>
              {name}
            </a>
            {hasSubMenuItems && <span className="triangle" />}
          </>
        ) : (
          <>
            <span data-nosnippet className={hasSubMenuItems ? 'has-children' : ''}>
              {name}
            </span>
            {hasSubMenuItems && <span className="triangle" />}
          </>
        )}
      </span>

      <AnimateHeight duration={100} height={isExpanded ? 'auto' : 0} animateOpacity>
        {!isRunningOnServer() && (
          <div className="expansion">
            <section className="nav-section-offset">
              <div className="container-fluid">
                <div className={`heading${headingHasIcon ? '' : ' heading-no-icon'}`}>
                  {headingHasIcon && <img src={icon} alt="Heading Section Icon" />}
                  <p className="menu-heading" dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            </section>
            <section className="nav-section-offset">
              <div className="container-fluid">
                <div className="business-units">
                  {hasBusinessUnits &&
                    businessUnitMenuItems.map(businessUnit =>
                      showMenuItemsAsTiles ? (
                        <BusinessUnitButton
                          key={businessUnit.guid}
                          businessUnitDetails={businessUnit}
                          selectBusinessUnit={selectBusinessUnit}
                          isActive={
                            selectedBusinessUnit && selectedBusinessUnit.guid === businessUnit.guid
                          }
                        />
                      ) : (
                        <a
                          className="business-unit"
                          key={businessUnit.guid}
                          href={businessUnit.landingPageUrl}
                          title={businessUnit}
                        >
                          {businessUnit.title}
                        </a>
                      )
                    )}
                  {!hasBusinessUnits &&
                    showMenuItemsAsTiles &&
                    children.map(link => (
                      <a
                        className="business-unit"
                        key={link.name}
                        href={link.url}
                        target={link.target}
                        title={`Go To ${link.name}`}
                      >
                        {link.name}
                      </a>
                    ))}
                </div>
                {selectedBusinessUnit && <BusinessUnitDetail {...selectedBusinessUnit} />}
              </div>
            </section>

            {hasValidSubLinks && !showMenuItemsAsTiles && (
              <section className="nav-section">
                <div className="container-fluid">
                  <div className="links">
                    {children.map(link => {
                      return (
                        <a
                          key={link.name}
                          href={link.url}
                          title={`Go To ${link.name}`}
                          target={link.target}
                        >
                          {link.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </section>
            )}
          </div>
        )}
      </AnimateHeight>
    </li>
  );
};

DesktopMenuItem.defaultProps = {
  businessUnitMenuItems: [],
  children: [],
  content: '',
  icon: '',
  isActive: false,
  name: '',
  showMenuItemsAsTiles: false,
  target: '',
  url: ''
};

DesktopMenuItem.propTypes = {
  businessUnitMenuItems: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.string,
  currentUrl: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  showMenuItemsAsTiles: PropTypes.bool,
  target: PropTypes.string,
  url: PropTypes.string
};

export default DesktopMenuItem;
