import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '../../../images/icons/layout/Search.svg';
import CloseIcon from '../../../images/icons/layout/Close-Blue.svg';

import { getSearchSuggestions } from '../../../services/careersSearch.service';
// import goToRedirect from '../../../services/redirect.service';

const SearchBarComponent = ({ getCareers }) => {
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const hasSearchSuggestions = searchSuggestions && searchSuggestions.length;
  const dropdownOpen = hasSearchSuggestions || isOpen;

  const closeDropdown = () => {
    setIsOpen(false);
    setSearchSuggestions([]);
  };

  const closeDropdownClearValue = () => {
    setIsOpen(false);
    setSearchSuggestions([]);
    setInputValue('');
  };

  return (
    <div className="search">
      <div className="input-wrapper" onBlur={() => closeDropdown()}>
        <form onSubmit={e => e.preventDefault()}>
          <input
            className="search-input"
            onChange={async event => {
              setInputValue(event.target.value);
              setSearchSuggestions(await getSearchSuggestions(3, event.target.value, true));
            }}
            placeholder="Search Job Opportunities"
            type="text"
            value={inputValue}
          />
          {inputValue && (
            <button type="button" className="close-icon" onClick={() => closeDropdownClearValue()}>
              <img src={CloseIcon} alt="Close" />
            </button>
          )}
          <button
            type="submit"
            onClick={async () => {
              closeDropdown();
              await getCareers(!inputValue ? '*' : inputValue);

              // Calling again incase any straggling calls
              // finish between the first closeDropdown and
              // the response of getCareers
              closeDropdown();
            }}
          >
            <img src={SearchIcon} alt="Search" />
          </button>
        </form>
        {dropdownOpen && (
          <div className="predictive-dropdown">
            {searchSuggestions.map(item => (
              <div className="dropdown-item" key={item.url}>
                {item.url ? (
                  <a
                    href={item.url}
                    title="Predictive Item URL"
                    onMouseDown={e => e.preventDefault()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    <span className="url">{`${item.category} | ${item.location}`}</span>
                  </a>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: item.text }} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

SearchBarComponent.propTypes = {
  getCareers: PropTypes.func.isRequired
};

export default SearchBarComponent;
