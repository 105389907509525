import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import GlobalComponentContainer from '../GlobalComponentContainer';
import ContentDivider from '../shared/ContentDivider';
import Faq from './Faq';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const FaqComponent = ({
  faqs,
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  upArrow,
  downArrow
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="faq-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            {title && (
              <GlobalComponentContainer
                {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
              />
            )}
            <div className="faq-container">
              {faqs.map(faq => (
                <Faq
                  answer={faq.answer}
                  faqs={faq.children}
                  key={faq.question}
                  prettyUrlTitle={faq.prettyUrlTitle}
                  question={faq.question}
                  upArrow={upArrow}
                  downArrow={downArrow}
                />
              ))}
            </div>
          </ConditionalWrapper>
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

FaqComponent.defaultProps = {
  faqs: [],
  hasContentDivider: false,
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  title: null,
  upArrow: '',
  downArrow: ''
};

FaqComponent.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object),
  hasContentDivider: PropTypes.bool,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  upArrow: PropTypes.string,
  downArrow: PropTypes.string
};

export default FaqComponent;
