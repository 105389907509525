import React, { useRef } from 'react';

import { whiteLabelSmallHeroBanner } from '../../../types';

import {
  SLIDE_CONTENT_POSITION_CENTER,
  SLIDE_CONTENT_POSITION_LEFT,
  SLIDE_CONTENT_POSITION_RIGHT
} from '../../../constants/slide-content-positions';

import CtaButton from '../../shared/ctaButton/CtaButton';
import { useCssVariables } from '../../../utilities';

const getJustifyContentValue = contentPosition => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'flex-end';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'flex-start';
  }
};

const getTextAlignValue = contentPosition => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'right';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'left';
  }
};

const WhiteLabelSmallHeroBanner = ({ heroSlide }) => {
  const hasCtaButton = !!heroSlide.ctaButton.path;
  const hasSecondCtaButton = !!heroSlide.ctaButton.secondPath;
  const slideRef = useRef(null);

  useCssVariables(slideRef, {
    '--background-color': `${heroSlide.backgroundColor || 'unset'}`,
    '--text-color': `${heroSlide.textColor || 'unset'}`,
    '--slide-text-color': `${heroSlide.textColor || 'unset'}`,
    '--slide-justify-content': getJustifyContentValue(heroSlide.textPosition),
    '--slide-text-align': getTextAlignValue(heroSlide.contentPosition),
    '--button-text-color': `${heroSlide.ctaButton.textColor || 'unset'}`,
    '--button-justify-content': getJustifyContentValue(heroSlide.ctaButton.position)
  });

  return (
    <div className="item" ref={slideRef}>
      <div className="color-wrapper">
        <div className="content">
          <div className="container-fluid slide-content">
            <div className="inner-content">
              <h1>
                <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
              </h1>
              <p dangerouslySetInnerHTML={{ __html: heroSlide.eyebrowText }} />
            </div>
          </div>
          {hasCtaButton && (
            <div className="inner-cta button">
              <CtaButton
                backgroundColor={heroSlide.ctaButton.backgroundColor}
                path={heroSlide.ctaButton.path}
                target={heroSlide.ctaButton.target}
                textColor={heroSlide.ctaButton.textColor}
              >
                {heroSlide.ctaButton.text}
              </CtaButton>
              {hasSecondCtaButton && (
                <CtaButton
                  className="second"
                  backgroundColor={heroSlide.ctaButton.secondBackgroundColor}
                  path={heroSlide.ctaButton.secondPath}
                  target={heroSlide.ctaButton.secondTarget}
                  textColor={heroSlide.ctaButton.secondTextColor}
                >
                  {heroSlide.ctaButton.secondText}
                </CtaButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

WhiteLabelSmallHeroBanner.propTypes = {
  heroSlide: whiteLabelSmallHeroBanner.isRequired
};

export default WhiteLabelSmallHeroBanner;
