import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import { productNavigationItem } from '../../../types';
import ContentDivider from '../shared/ContentDivider';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

import arrowLeft from '../../../images/icons/layout/Arrow-Left-Blue.svg';
import arrowRight from '../../../images/icons/layout/Arrow-Right-Blue.svg';
import { PREVIOUS, NEXT } from '../../../constants/expansion-titles';

const ProductNavigationComponent = ({
  hasContentDivider,
  nextProduct,
  prettyUrlTitle,
  previousProduct
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <div className="section-product-navigation">
            {previousProduct && (
              <div className="button prev">
                <a href={previousProduct.url} title={`Go to ${previousProduct.title}`}>
                  <span>
                    <img src={arrowLeft} alt="Back Arrow" />
                    {PREVIOUS}
                  </span>
                  <h3>{previousProduct.title}</h3>
                </a>
              </div>
            )}
            {nextProduct && (
              <div className="button next">
                <a href={nextProduct.url} title={`Go to ${nextProduct.title}`}>
                  <span>
                    {NEXT}
                    <img src={arrowRight} alt="Next Arrow" />
                  </span>
                  <h3>{nextProduct.title}</h3>
                </a>
              </div>
            )}
          </div>
          {hasContentDivider && <ContentDivider />}
        </ConditionalWrapper>
      </div>
    </section>
  );
};

ProductNavigationComponent.defaultProps = {
  hasContentDivider: false,
  nextProduct: {},
  prettyUrlTitle: '',
  previousProduct: {}
};

ProductNavigationComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  nextProduct: productNavigationItem,
  prettyUrlTitle: PropTypes.string,
  previousProduct: productNavigationItem
};

export default ProductNavigationComponent;
