import React from 'react';
import PropTypes from 'prop-types';
import LazyLoadImg from '../shared/LazyLoadImg';

import { sizedImageShape } from '../../../types';

const Item = ({ altText, description, image, link, target, borderColor }) => {
  const hasImage = image && image.url;
  const borderStyle = {
    border: '2px solid ',
    color: borderColor
  };

  return (
    <div className="item">
      <div className="inner">
        {hasImage &&
          (link ? (
            <a href={link} {...target && { target }}>
              {borderColor ? (
                <LazyLoadImg src={image} alt={altText || 'item icon'} style={borderStyle} />
              ) : (
                <LazyLoadImg src={image} alt={altText || 'item icon'} />
              )}
            </a>
          ) : borderColor ? (
            <LazyLoadImg src={image} alt={altText || 'item icon'} style={borderStyle} />
          ) : (
            <LazyLoadImg src={image} alt={altText || 'item icon'} />
          ))}
        {description && <div className="text" dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
    </div>
  );
};

Item.defaultProps = {
  altText: null,
  description: null,
  image: {},
  link: null,
  target: null,
  borderColor: null
};

Item.propTypes = {
  altText: PropTypes.string,
  description: PropTypes.string,
  image: sizedImageShape,
  link: PropTypes.string,
  target: PropTypes.string,
  borderColor: PropTypes.string
};

export default Item;
