import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import localStorage from 'local-storage';

import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';

import SearchBarComponent from '../widgets/searchBar/SearchBarComponent';
import AnchorWrapper from '../layout/shared/AnchorWrapper';
import PageWasLoadedFromBackOrForwardOperation from '../../services/browser.service';

import TabletAndMobileContainer from '../shared/TabletAndMobileContainer';
import Pager from '../shared/pager/Pager';

import { getSearch, submitClick } from '../../services/search.service';
import { isRunningOnServer } from '../../utilities';

const SearchLanding = ({
  alerts,
  businessUnitFilters,
  initialSearchText,
  miscFilters,
  noResultsText,
  searchLinks
}) => {
  const STOREDSEARCHPARAMETERS = 'storedSearchParameters';
  const ALL = { audiences: null, key: 'All', title: 'All' };
  const ALLRESULTS = { displayValue: 'All Results', key: null, value: null };
  const itemsPerPage = 10;
  const [businessUnitFilterState, setSelectedBUFilter] = useState(ALL);
  const [searchItems, setSearchItems] = useState();
  const [searchText, setSearchText] = useState();
  const [searchId, setSearchId] = useState();
  const [pagerInfo, setPagerInfo] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(ALLRESULTS);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const showResults = searchItems && searchItems.length > 0;
  const hasAlerts = !!alerts && !!alerts.length;

  const search = async (query, page, businessUnitFilter, filter) => {
    const searchFilter = filter == null ? currentFilter : filter;
    const buFilter = businessUnitFilter == null ? businessUnitFilterState : businessUnitFilter;

    localStorage.set(STOREDSEARCHPARAMETERS, {
      query,
      page,
      businessUnitFilter: buFilter,
      searchFilter
    });

    setCurrentPage(page);
    setSearchText(query);
    setSelectedBUFilter(buFilter);
    setCurrentFilter(searchFilter);

    const response = await getSearch(
      query,
      page,
      itemsPerPage,
      buFilter.title === ALL.title ? '' : buFilter.title,
      searchFilter.key === null ? null : searchFilter
    );

    if (response) {
      setSearchItems(response.listing.items);
      setPagerInfo(response.listing.pager);
      setSearchId(response.searchId);

      scroller.scrollTo('scroll', {
        smooth: true,
        duration: 500,
        delay: 150,
        offset: hasAlerts ? -200 : -150
      });
    }
  };

  const toggleBUFilters = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
    setIsFilterExpanded(false);
  };

  const toggleFilters = () => {
    setIsFilterExpanded(!isFilterExpanded);
    setIsAccordionExpanded(false);
  };

  const getItemCountRange = () => {
    const start = (pagerInfo.currentPage - 1) * itemsPerPage + 1;
    const end =
      pagerInfo.currentPage * pagerInfo.pageSize < pagerInfo.totalItems
        ? pagerInfo.currentPage * pagerInfo.pageSize
        : pagerInfo.totalItems;

    return `${start}-${end}`;
  };

  const onSearch = async (query, page) => {
    await search(query, page, businessUnitFilterState, currentFilter);
  };

  const modifyFilter = async filter => {
    setCurrentFilter(filter);
    await search(searchText, 1, businessUnitFilterState, filter);
  };

  const setBusinessUnitFilter = businessUnitFilter => {
    setSelectedBUFilter(businessUnitFilter);
    setIsAccordionExpanded(false);
    search(searchText, 1, businessUnitFilter, currentFilter);
  };

  const submitClickAndNavigate = item => {
    const newItem = item;
    newItem.searchId = searchId;

    submitClick(newItem);

    window.location.href = item.url;
  };

  if (!isPageLoaded) {
    setIsPageLoaded(true);

    const storedSearchParameters = localStorage.get(STOREDSEARCHPARAMETERS);
    localStorage.remove(STOREDSEARCHPARAMETERS);

    if (storedSearchParameters != null && PageWasLoadedFromBackOrForwardOperation()) {
      search(
        storedSearchParameters.query,
        storedSearchParameters.page,
        storedSearchParameters.businessUnitFilter == null
          ? ALL
          : storedSearchParameters.businessUnitFilter,
        storedSearchParameters.searchFilter
      );
    } else if (initialSearchText) {
      search(initialSearchText, currentPage);
    }
  }

  return (
    <>
      <div className="search-landing section-white">
        <section className="search-bar">
          <SearchBarComponent
            colorScheme="light"
            isFloatingBetweenComponents={false}
            isFocused
            onSearch={onSearch}
            query={searchText}
            redirectToSearch={false}
            searchLinks={[]}
            title=""
          />
        </section>
        <Element name="scroll" />
        <section className="util">
          <div className="filters">
            <div className="container-fluid">
              <div className="filters-container full-width">
                <div className="business-unit-list br-lg">
                  {!isRunningOnServer() && businessUnitFilters && businessUnitFilters.length > 0 && (
                    <ul>
                      <li>
                        <button
                          type="button"
                          className={`btn-filter ${
                            businessUnitFilterState.key === ALL.key ? 'active' : ''
                          }`}
                          onClick={() => setBusinessUnitFilter(ALL)}
                        >
                          {ALL.title}
                        </button>
                      </li>
                      {businessUnitFilters.map(businessUnitFilter => (
                        <li key={businessUnitFilter.key}>
                          <button
                            type="button"
                            className={`btn-filter ${
                              businessUnitFilterState.key === businessUnitFilter.key ? 'active' : ''
                            }`}
                            onClick={() => setBusinessUnitFilter(businessUnitFilter)}
                          >
                            {businessUnitFilter.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="accordion-list br-sm">
                  {businessUnitFilters && businessUnitFilters.length > 0 && (
                    <ul className="accordion">
                      <li className={`${isAccordionExpanded ? ' active' : ''}`}>
                        <button className="btn-no-style" type="button" onClick={toggleBUFilters}>
                          <span className="btn-contain">
                            {businessUnitFilterState &&
                              (businessUnitFilterState.title || 'All Trustmark')}
                          </span>
                          <img
                            src={isAccordionExpanded ? arrowUp : arrowDown}
                            className="chevron"
                            alt="Chevron Arrow"
                          />
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="accordion-list">
                  <ul className="accordion sort">
                    <li className={`${isFilterExpanded ? ' active' : ''}`}>
                      <button className="btn-no-style" type="button" onClick={toggleFilters}>
                        <span className="btn-contain">{currentFilter.displayValue}</span>
                        <img
                          src={isFilterExpanded ? arrowUp : arrowDown}
                          className="chevron"
                          alt="Chevron Arrow"
                        />
                      </button>
                    </li>
                    <AnimateHeight
                      duration={500}
                      height={isFilterExpanded ? 'auto' : 0}
                      animateOpacity
                      className="expanded-filters-list br-md"
                    >
                      <ul className="filters-list">
                        <li>
                          <button
                            className="btn-no-style"
                            type="button"
                            onClick={() => {
                              toggleFilters();
                              modifyFilter(ALLRESULTS);
                            }}
                          >
                            All Results
                          </button>
                        </li>
                        {miscFilters.map(value => (
                          <li key={value.displayValue}>
                            <button
                              className="btn-no-style"
                              type="button"
                              onClick={() => {
                                toggleFilters();
                                modifyFilter(value);
                              }}
                            >
                              {value.displayValue}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </AnimateHeight>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <TabletAndMobileContainer>
            <AnimateHeight
              duration={500}
              height={isAccordionExpanded ? 'auto' : 0}
              className="expanded-filters-list dropdown"
            >
              <div className="filters-list">
                <div className="container-fluid">
                  <ul>
                    <li>
                      <button
                        value={ALL.key}
                        type="button"
                        className="btn-filter"
                        onClick={() => setBusinessUnitFilter(ALL)}
                      >
                        {ALL.title}
                      </button>
                    </li>
                    {businessUnitFilters.map(businessUnitFilter => (
                      <li key={businessUnitFilter.key}>
                        <button
                          value={businessUnitFilter.key}
                          type="button"
                          className="btn-filter"
                          onClick={() => setBusinessUnitFilter(businessUnitFilter)}
                        >
                          {businessUnitFilter.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </AnimateHeight>
            <AnimateHeight
              duration={500}
              height={isFilterExpanded ? 'auto' : 0}
              animateOpacity
              className="expanded-filters-list dropdown"
            >
              <ul className="filters-list">
                <li>
                  <button
                    className="btn-no-style"
                    type="button"
                    onClick={() => {
                      toggleFilters();
                      modifyFilter(ALLRESULTS);
                    }}
                  >
                    {ALLRESULTS.displayValue}
                  </button>
                </li>
                {miscFilters.map(value => (
                  <li key={value.displayValue}>
                    <button
                      className="btn-no-style"
                      type="button"
                      onClick={() => {
                        toggleFilters();
                        modifyFilter(value);
                      }}
                    >
                      {value.displayValue}
                    </button>
                  </li>
                ))}
              </ul>
            </AnimateHeight>
          </TabletAndMobileContainer>
        </section>

        {showResults && pagerInfo && (
          <div className="count">
            <div className="container-fluid">
              <span className="section-header">
                {`${getItemCountRange()} of
                  ${pagerInfo.totalItems}
                  Items Matching your Search Criteria`}
              </span>
            </div>
          </div>
        )}

        <section className="section-gutter">
          <div className="container-fluid">
            <div className="search-list">
              {showResults ? (
                searchItems.map((item, index) => (
                  <div className="result" key={item.id}>
                    <button
                      className="btn-no-style"
                      type="button"
                      title="Search Result"
                      onClick={() =>
                        submitClickAndNavigate({
                          rank: index + (currentPage - 1) * itemsPerPage,
                          searchItemId: item.searchItemId,
                          url: item.url
                        })
                      }
                    >
                      <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                      <span className="url">{item.url}</span>
                      <p dangerouslySetInnerHTML={{ __html: item.content }} />
                      {item.businessUnitTitle && (
                        <span className="tag">{item.businessUnitTitle}</span>
                      )}
                    </button>
                  </div>
                ))
              ) : (
                <>
                  <span
                    className="no-results"
                    dangerouslySetInnerHTML={{ __html: noResultsText }}
                  />
                  {searchLinks && (
                    <span className="search-links">
                      {searchLinks.map(link => (
                        <AnchorWrapper
                          url={link.url}
                          target={link.target}
                          key={link.name}
                          name={link.name}
                        >
                          <>{link.name}</>
                        </AnchorWrapper>
                      ))}
                    </span>
                  )}
                </>
              )}
            </div>
            {showResults && pagerInfo && (
              <Pager
                search={search}
                searchText={searchText}
                currentPage={currentPage}
                pagerInfo={pagerInfo}
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

SearchLanding.defaultProps = {
  alerts: [],
  businessUnitFilters: [],
  initialSearchText: '',
  miscFilters: [],
  searchLinks: []
};

SearchLanding.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  businessUnitFilters: PropTypes.arrayOf(PropTypes.object),
  initialSearchText: PropTypes.string,
  miscFilters: PropTypes.arrayOf(PropTypes.object),
  noResultsText: PropTypes.string.isRequired,
  searchLinks: PropTypes.arrayOf(PropTypes.object)
};

export default SearchLanding;
