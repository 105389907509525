import React from 'react';
import PropTypes from 'prop-types';

import { searchHeaderShape } from '../../types';
import DataTableComponent from '../shared/dataTable/DataTableComponent';
import StateSelectorComponent from './StateSelectorComponent';

const ContactUsGlobalComponent = ({
  hideStateSelector,
  initialState,
  rows,
  searchHeader,
  states
}) => {
  return (
    <div className="container-fluid">
      <DataTableComponent rows={rows} />
      {!hideStateSelector && <StateSelectorComponent {...{ initialState, searchHeader, states }} />}
    </div>
  );
};

ContactUsGlobalComponent.defaultProps = {
  initialState: '',
  searchHeader: {}
};

ContactUsGlobalComponent.propTypes = {
  hideStateSelector: PropTypes.bool.isRequired,
  initialState: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ContactUsGlobalComponent;
