import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import { searchHeaderShape } from '../../../types';
import StateSelectorComponent from '../../contactUs/StateSelectorComponent';

import ContentDivider from '../shared/ContentDivider';

const ProductNetworkAvailabilityComponent = ({
  hasContentDivider,
  prettyUrlTitle,
  searchHeader,
  states
}) => {
  return (
    <section className="section-white section-network-availability">
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
      >
        <div className="container-fluid">
          {states && (
            <StateSelectorComponent
              {...{
                searchHeader,
                states,
                showContentDividerInitialState: false,
                showDataTableContentDivider: false
              }}
            />
          )}
          {hasContentDivider && <ContentDivider />}
        </div>
      </ConditionalWrapper>
    </section>
  );
};

ProductNetworkAvailabilityComponent.defaultProps = {
  hasContentDivider: false,
  prettyUrlTitle: '',
  searchHeader: {},
  states: []
};

ProductNetworkAvailabilityComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  prettyUrlTitle: PropTypes.string,
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object)
};

export default ProductNetworkAvailabilityComponent;
