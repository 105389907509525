import React from 'react';

import SmallHeroSlide from './SmallHeroSlide';
import { smallHeroSlide } from '../../../types';

const SmallHeroWidget = ({ heroSlide }) => {
  return (
    <section className="hero hero-small">
      <SmallHeroSlide heroSlide={heroSlide} />
    </section>
  );
};

SmallHeroWidget.propTypes = {
  heroSlide: smallHeroSlide.isRequired
};

export default SmallHeroWidget;
