export default function PageWasLoadedFromBackOrForwardOperation() {
  if (window.performance) {
    const navEntries = window.performance.getEntriesByType('navigation');

    if (navEntries.length > 0 && navEntries[0].type === 'back_forward') {
      // As per API lv2, this page was loaded from a back/forward operation
      return true;
    }

    if (
      window.performance.navigation &&
      window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD
    ) {
      // As per API lv1, this page was loaded from a back/forward operation
      return true;
    }
  }

  return false;
}
