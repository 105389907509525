import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '../shared/ContentDivider';
import AnchorWrapper from '../../layout/shared/AnchorWrapper';

const ProductsByBUComponent = ({ hasContentDivider, prettyUrlTitle, products, title }) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <div className="section-products-list">
              <h2>{title}</h2>
              <ul className="list">
                {products &&
                  products.map(item => (
                    <li className="item" key={item.name}>
                      <AnchorWrapper url={item.url} target="_self" name={item.name}>
                        <>{item.name}</>
                      </AnchorWrapper>
                    </li>
                  ))}
              </ul>
            </div>
          </ConditionalWrapper>
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

ProductsByBUComponent.defaultProps = {
  hasContentDivider: false,
  prettyUrlTitle: '',
  products: []
};

ProductsByBUComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  prettyUrlTitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default ProductsByBUComponent;
