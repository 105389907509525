import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { newLineToBreak } from '../../utilities';
import FooterMenuItem from './shared/FooterMenuItem';
import LegalMenuItem from './shared/LegalMenuItem';
import { globalImage, ccpaBannerShape } from '../../types';

import CCPABanner from '../shared/ccpaBanner/CCPABanner';

const Footer = ({
  brightEdgeBody,
  brightEdgeClose,
  ccpaBanner,
  copyright,
  corporateAddress,
  footerLegalText,
  footerMenuItems,
  globalImages,
  hideFooterMenu,
  isMobileNavigation,
  legalMenuItems,
  isWhiteLabel
}) => {
  const mobileFooterMenuItems =
    footerMenuItems && footerMenuItems.filter(item => !!item.isVisibleOnMobileNavigation);

  const menuItems = () => {
    return isMobileNavigation ? mobileFooterMenuItems : footerMenuItems;
  };

  const [hasCCPABanner, setHasCCPABanner] = useState(ccpaBanner);
  return (
    <>
      <div className="container-fluid">
        <div className={`footer-wrap ${isWhiteLabel ? 'white-label' : ''}`}>
          {!isMobileNavigation && (!hideFooterMenu || isWhiteLabel) && (
            <div className="col-lg-3 logo-wrapper">
              <div className="logo">
                <a href="/" title="Go to Home">
                  <img
                    alt="Trustmark Logo"
                    src={globalImages.logo && globalImages.logo.directUrl}
                  />
                </a>
              </div>
            </div>
          )}
          {!hideFooterMenu &&
            menuItems() &&
            menuItems().map((item, index) => (
              <div key={item.name} className="col-lg-3">
                <ul>
                  <FooterMenuItem {...item} />
                </ul>
                {index === footerMenuItems.length - 1 && !isMobileNavigation && (
                  <p className="address">
                    {newLineToBreak(corporateAddress)}
                    {copyright}
                  </p>
                )}
              </div>
            ))}
          <div className={`col subfooter${hideFooterMenu ? ' no-items' : ''}`}>
            {!isWhiteLabel &&
              legalMenuItems &&
              legalMenuItems.map(item => <LegalMenuItem key={item.name} {...item} />)}
            <div className="legal-text" dangerouslySetInnerHTML={{ __html: footerLegalText }} />
            <div
              className="be-ix-link-block"
              dangerouslySetInnerHTML={{ __html: `${brightEdgeBody} ${brightEdgeClose}` }}
            />
          </div>
        </div>
      </div>
      {hasCCPABanner && <CCPABanner ccpaBanner={ccpaBanner} setHasCCPABanner={setHasCCPABanner} />}
    </>
  );
};

Footer.defaultProps = {
  brightEdgeBody: '',
  brightEdgeClose: '',
  ccpaBanner: {},
  footerLegalText: '',
  hideFooterMenu: false,
  isWhiteLabel: false
};

Footer.propTypes = {
  brightEdgeBody: PropTypes.string,
  brightEdgeClose: PropTypes.string,
  ccpaBanner: ccpaBannerShape,
  copyright: PropTypes.string.isRequired,
  corporateAddress: PropTypes.string.isRequired,
  footerLegalText: PropTypes.string,
  footerMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hideFooterMenu: PropTypes.bool,
  isMobileNavigation: PropTypes.bool.isRequired,
  legalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isWhiteLabel: PropTypes.bool
};

export default Footer;
