import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  getNewsroomData,
  isSelected,
  label,
  modifyFilters,
  setFilters,
  type,
  value
}) => {
  return (
    <div key={value} className="form-group">
      <input
        id={value}
        type="checkbox"
        checked={isSelected}
        onChange={() => {
          const filters = modifyFilters(type, value, false);
          setFilters(filters);
          getNewsroomData(1, filters, false);
        }}
      />
      <label htmlFor={value} name={value}>
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  getNewsroomData: () => {},
  label: '',
  modifyFilters: () => {},
  setFilters: () => {},
  type: ''
};

Checkbox.propTypes = {
  getNewsroomData: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string,
  modifyFilters: PropTypes.func,
  setFilters: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
};

export default Checkbox;
