import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Element } from 'react-scroll';
import GlobalComponentContainer from '../GlobalComponentContainer';
import { globalComponentProperties } from '../../../types';

import VideoModal from '../shared/VideoModal';
import VideoItemComponent from '../shared/VideoItemComponent';

import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const FeaturedContentVideoComponent = ({ header, videos }) => {
  const [selectedContentGuid, selectContent] = useState(null);

  const isGrid = videos && videos.length > 2;

  const gridClass = isGrid ? 'grid' : '';

  const setSelectedContent = guid => {
    if (guid === selectedContentGuid) {
      selectContent(null);
    } else {
      selectContent(guid);
    }
  };

  const selectedContent = videos && videos.find(item => item.guid === selectedContentGuid);

  const Grid = ({ children }) =>
    isGrid ? <div className="sub-flex-box">{children}</div> : children;

  ReactModal.setAppElement('#wrapper');

  return (
    <section className="section-white">
      <div className="container-fluid">
        <div
          className={`featured-video section-gutter ${
            header.isSingleVideoFeatured ? 'single-video-featured' : ''
          }`}
        >
          <ConditionalWrapper
            condition={header.prettyUrlTitle}
            wrapper={children => <Element name={header.prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer {...header} />
            <TabletAndMobileContainer>
              <div className="section-intro section-gutter-half">
                {header.isSingleVideoFeatured ? (
                  <VideoItemComponent
                    videoItem={videos[0]}
                    gridClass=""
                    dynamicImage={videos[0].image}
                    selectContent={setSelectedContent}
                  />
                ) : (
                  <>
                    {videos && videos.length > 0 && (
                      <VideoItemComponent
                        videoItem={videos[0]}
                        gridClass={gridClass}
                        dynamicImage={videos[0].imageHalf}
                        selectContent={setSelectedContent}
                      />
                    )}
                    {videos && videos.length > 1 && (
                      <Grid count={videos.length}>
                        {videos.slice(1).map(item => (
                          <VideoItemComponent
                            key={item.guid}
                            videoItem={item}
                            dynamicImage={item.imageHalf}
                            gridClass={gridClass}
                            selectContent={setSelectedContent}
                          />
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </div>
            </TabletAndMobileContainer>
            <DesktopContainer>
              <div className="section-intro section-gutter-half">
                {header.isSingleVideoFeatured ? (
                  <VideoItemComponent
                    videoItem={videos[0]}
                    dynamicImage={videos[0].image}
                    selectContent={setSelectedContent}
                  />
                ) : (
                  <>
                    {videos && (
                      <VideoItemComponent
                        videoItem={videos[0]}
                        gridClass={gridClass}
                        dynamicImage={videos.length === 1 ? videos[0].imageHalf : videos[0].image}
                        selectContent={setSelectedContent}
                      />
                    )}
                    {videos && videos.length > 1 && (
                      <Grid count={videos.length}>
                        {videos.slice(1).map(item => (
                          <VideoItemComponent
                            key={item.guid}
                            videoItem={item}
                            dynamicImage={videos.length === 3 ? item.imageHalf : item.image}
                            gridClass={gridClass}
                            selectContent={setSelectedContent}
                          />
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </div>
            </DesktopContainer>
            <VideoModal
              {...selectedContent}
              selectFunction={setSelectedContent}
              isActive={!!selectedContentGuid}
            />
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

FeaturedContentVideoComponent.defaultProps = {
  children: null,
  videos: []
};

FeaturedContentVideoComponent.propTypes = {
  children: PropTypes.string,
  header: PropTypes.shape(globalComponentProperties).isRequired,
  videos: PropTypes.arrayOf(PropTypes.object)
};

export default FeaturedContentVideoComponent;
