export const SET_IS_SCROLL_PAST_NAV = 'SET_IS_SCROLL_PAST_NAV';
export const SET_IS_SCROLL_PAST_FOOTER = 'SET_IS_SCROLL_PAST_FOOTER';

export function setIsScrollPastNavAction(isScrollPastNav) {
  return {
    type: SET_IS_SCROLL_PAST_NAV,
    isScrollPastNav
  };
}

export function setIsScrollPastFooterAction(isScrollPastFooter) {
  return {
    type: SET_IS_SCROLL_PAST_FOOTER,
    isScrollPastFooter
  };
}
