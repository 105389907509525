import React from 'react';
import PropTypes from 'prop-types';

import { searchHeaderShape } from '../../types';
import DataTableComponent from '../shared/dataTable/DataTableComponent';
import StateSelectorComponent from './StateSelectorComponent';

const ContactUsAudienceComponent = ({ initialState, rows, searchHeader, states }) => {
  return (
    <div className="container-fluid">
      {states && <StateSelectorComponent {...{ initialState, searchHeader, states }} />}
      {rows && <DataTableComponent rows={rows} />}
    </div>
  );
};

ContactUsAudienceComponent.defaultProps = {
  initialState: '',
  rows: [],
  searchHeader: {},
  states: []
};

ContactUsAudienceComponent.propTypes = {
  initialState: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object)
};

export default ContactUsAudienceComponent;
