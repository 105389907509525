import React, { useState } from 'react';
import { Element } from 'react-scroll';

import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import chevronDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import chevronUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const FaqSecondary = ({ answer, prettyUrlTitle, question }) => {
  const [isFaqVisible, setIsFaqVisible] = useState(false);

  const toggleFaq = () => {
    setIsFaqVisible(!isFaqVisible);
  };
  return (
    <div className={`faq secondary ${isFaqVisible ? 'open' : 'close'}`}>
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={children => (
          <Element name={prettyUrlTitle} id={prettyUrlTitle} className="anchor-wrapper">
            {children}
          </Element>
        )}
      >
        <button className="btn-no-style secondary" type="button" onClick={toggleFaq}>
          <img src={isFaqVisible ? chevronUp : chevronDown} className="icon" alt="Chevron Arrow" />
          {question}
        </button>
      </ConditionalWrapper>
      <AnimateHeight duration={500} height={isFaqVisible ? 'auto' : 0}>
        {answer && <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />}
      </AnimateHeight>
    </div>
  );
};

FaqSecondary.defaultProps = {
  answer: '',
  prettyUrlTitle: ''
};

FaqSecondary.propTypes = {
  answer: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  question: PropTypes.string.isRequired
};

export default FaqSecondary;
