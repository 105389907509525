import React from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';

import arrowUp from 'images/icons/layout/Arrow-Up-Blue.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import CloseIcon from 'images/icons/layout/Close-Blue.svg';

import { selectedItemShape } from '../../../types';

const PredictiveSearch = ({
  isFiltered,
  items,
  onChange,
  onInputValueChange,
  onStateChange,
  placeholder,
  regex,
  selectedInputValue,
  selectedItem
}) => {
  return (
    <Downshift
      id="downshift"
      inputValue={selectedInputValue}
      itemToString={product => (product ? product.name : '')}
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      onStateChange={onStateChange}
      selectedItem={selectedItem}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu
      }) => (
        <div className="predictive-search-container">
          <div className="predictive-search">
            {inputValue && (
              <button
                type="button"
                className="btn-no-style close-icon"
                onClick={async () => {
                  // DO NOT REMOVE AWAIT
                  // TypeScript say's it's not needed,
                  // but it is. Trust me...
                  await clearSelection();
                  openMenu();
                }}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            )}
            <button className="btn-no-style" type="button" {...getToggleButtonProps()}>
              <img src={isOpen ? arrowUp : arrowDown} className="chevron" alt="Chevron Arrow" />
            </button>
            <label className="sr-only" htmlFor="downshift-input" id="downshift-label">
              Predictive Search Input
            </label>
            <input {...getInputProps({ placeholder })} className="search-input" type="text" />

            {isOpen && (
              <div className="predictive-dropdown">
                {items
                  .filter(
                    item =>
                      !inputValue || item.name.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <div
                      {...getItemProps({
                        key: item.name,
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? '#ddd' : ''
                        }
                      })}
                      className="dropdown-item"
                      dangerouslySetInnerHTML={{
                        __html: `${item.name.replace(regex, highlight => {
                          return `<span class='highlight'>${highlight}</span>`;
                        })} ${
                          !isFiltered && item.title && item.title.length ? `(${item.title})` : ''
                        }`
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Downshift>
  );
};

PredictiveSearch.defaultProps = {
  isFiltered: false,
  items: [],
  onChange: () => {},
  onInputValueChange: () => {},
  onStateChange: () => {},
  placeholder: '',
  products: [],
  selectedInputValue: '',
  selectedItem: {}
};

PredictiveSearch.propTypes = {
  isFiltered: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onInputValueChange: PropTypes.func,
  onStateChange: PropTypes.func,
  placeholder: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  regex: PropTypes.instanceOf(RegExp).isRequired,
  selectedInputValue: PropTypes.string,
  selectedItem: selectedItemShape
};

export default PredictiveSearch;
