import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { globalComponentProperties, globalComponentDefaults } from '../../../types';

import GlobalComponentContainer from '../GlobalComponentContainer';
import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';
import Tile from '../../shared/tile/Tile';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

import BusinessUnitTileExpansion from './BusinessUnitTileExpansion';
import BusinessUnitModalExpansion from './BusinessUnitModalExpansion';

const FourBuComponent = ({
  businessUnitTiles,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title
}) => {
  const [selectedBusinessUnitGuid, selectBusinessUnit] = useState();

  const setSelectedBusinessUnit = guid => {
    if (guid === selectedBusinessUnitGuid) {
      selectBusinessUnit();
    } else {
      selectBusinessUnit(guid);
    }
  };

  const selectedBusinessUnit =
    businessUnitTiles && businessUnitTiles.find(tile => tile.guid === selectedBusinessUnitGuid);

  return (
    <section className="section-offset">
      <div className="container-fluid">
        <div className="four-bu-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer
              parentPageTitle={parentPageTitle}
              parentPageUrl={parentPageUrl}
              shortDescription={shortDescription}
              title={title}
            />
            <div className="tiles">
              {businessUnitTiles &&
                businessUnitTiles.map(tile => (
                  <Tile
                    key={tile.title}
                    {...tile}
                    selectItem={setSelectedBusinessUnit}
                    isActive={tile.guid === selectedBusinessUnitGuid}
                  />
                ))}
            </div>
            {selectedBusinessUnitGuid && (
              <>
                <DesktopContainer>
                  <BusinessUnitTileExpansion
                    {...selectedBusinessUnit}
                    selectBusinessUnit={setSelectedBusinessUnit}
                  />
                </DesktopContainer>
                <TabletAndMobileContainer>
                  <BusinessUnitModalExpansion
                    {...selectedBusinessUnit}
                    selectBusinessUnit={setSelectedBusinessUnit}
                    isActive={!!selectedBusinessUnitGuid}
                  />
                </TabletAndMobileContainer>
              </>
            )}
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

FourBuComponent.defaultProps = {
  businessUnitTiles: [],
  ...globalComponentDefaults,
  prettyUrlTitle: ''
};

FourBuComponent.propTypes = {
  businessUnitTiles: PropTypes.arrayOf(PropTypes.object),
  ...globalComponentProperties,
  prettyUrlTitle: PropTypes.string
};

export default FourBuComponent;
