import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import AnchorWrapper from '../../layout/shared/AnchorWrapper';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const SocialFooterComponent = ({ prettyUrlTitle, socialLinks, title }) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="social-footer section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <h2>{title}</h2>
            <ul>
              {socialLinks.map((link, index) => (
                <li className="inline" key={link.name || index}>
                  <AnchorWrapper {...link}>
                    <img src={link.icon} alt={`Social icon for ${link.name}`} />
                  </AnchorWrapper>
                </li>
              ))}
            </ul>
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

SocialFooterComponent.defaultProps = {
  prettyUrlTitle: '',
  socialLinks: []
};

SocialFooterComponent.propTypes = {
  prettyUrlTitle: PropTypes.string,
  socialLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SocialFooterComponent;
