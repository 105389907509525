import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '../GlobalComponentContainer';
import ContentDivider from '../shared/ContentDivider';

import Item from './ItemWithDescription';

const MaterialItemsWithDescriptionComponent = ({
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  thirdsGrid,
  title,
  items
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="material-item-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
            <div className={`item-container${thirdsGrid ? ' thirds' : ''}`}>
              {items.map(item => (
                <Item key={item.title} {...item} />
              ))}
            </div>
          </ConditionalWrapper>
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

MaterialItemsWithDescriptionComponent.defaultProps = {
  hasContentDivider: false,
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  thirdsGrid: false,
  title: null,
  items: []
};

MaterialItemsWithDescriptionComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  thirdsGrid: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
};

export default MaterialItemsWithDescriptionComponent;
