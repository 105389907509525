import React from 'react';
import PropTypes from 'prop-types';
import { valuesShape } from '../../../types';

import Checkbox from './Checkbox';

const FilterSection = ({ getNewsroomData, modifyFilters, setFilters, title, type, values }) => {
  return (
    <div className="form-group">
      <span className="section-header">{title}</span>
      {values.map(item => (
        <Checkbox
          getNewsroomData={getNewsroomData}
          isSelected={item.isSelected}
          key={item.value}
          label={item.label}
          modifyFilters={modifyFilters}
          setFilters={setFilters}
          type={type}
          value={item.value}
        />
      ))}
    </div>
  );
};

FilterSection.defaultProps = {
  getNewsroomData: () => {},
  modifyFilters: () => {},
  setFilters: () => {},
  title: '',
  type: '',
  values: []
};

FilterSection.propTypes = {
  getNewsroomData: PropTypes.func,
  modifyFilters: PropTypes.func,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.arrayOf(valuesShape)
};

export default FilterSection;
