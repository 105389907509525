import React from 'react';
import { Provider } from 'react-redux';
import getStore from '../../state/store';

const store = getStore();

const storeProvider = Component => props => (
  <Provider store={store}>
    <Component {...props} />
  </Provider>
);

export default storeProvider;
