import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import PlayIcon from '../shared/PlayIcon';
import LazyLoadImg from '../shared/LazyLoadImg';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import DesktopContainer from '../../shared/DesktopContainer';
import TabletAndMobileContainer from '../../shared/TabletAndMobileContainer';
import VideoModal from '../shared/VideoModal';

import { sizedImageShape, animationShape } from '../../../types';

const Item = ({
  guid,
  altText,
  description,
  image,
  link,
  target,
  title,
  videoUrl,
  playIconColor,
  animation
}) => {
  const hasAnimation = animation && animation.animationUrl;
  const hasImage = image && image.url;
  const hasMedia = hasAnimation || hasImage;

  const [selectedContentGuid, selectContent] = useState(null);

  const setSelectedContent = id => {
    if (id === selectedContentGuid) {
      selectContent(null);
    } else {
      selectContent(id);
    }
  };

  return (
    <>
      <div className="item">
        <div className="inner">
          {hasMedia && (
            <ConditionalWrapper
              condition={videoUrl}
              wrapper={children => (
                <button
                  className="btn-no-style relative"
                  type="button"
                  onClick={() => setSelectedContent(guid)}
                >
                  {children}
                </button>
              )}
            >
              {videoUrl && <PlayIcon iconColor={playIconColor} />}
              <ConditionalWrapper
                condition={link && !videoUrl}
                wrapper={children => (
                  <a href={link} {...target && { target }}>
                    {children}
                  </a>
                )}
              >
                {hasImage ? (
                  <LazyLoadImg src={image} alt={altText || 'item icon'} />
                ) : (
                  <>
                    <DesktopContainer>
                      <Player
                        src={animation.animationUrl}
                        background={animation.backgroundColor}
                        speed={animation.animationSpeed}
                        style={{
                          width: animation.width,
                          height: animation.height
                        }}
                        loop={animation.loop}
                        autoplay={animation.autoplay}
                        hover={animation.hover}
                        mode={animation.playMode}
                      />
                    </DesktopContainer>
                    <TabletAndMobileContainer>
                      <Player
                        src={animation.animationUrl}
                        background={animation.backgroundColor}
                        speed={animation.animationSpeed}
                        style={{
                          width: animation.deviceWidth,
                          height: animation.deviceHeight
                        }}
                        loop={animation.loop}
                        autoplay={animation.autoplay}
                        hover={animation.hover}
                        mode={animation.playMode}
                      />
                    </TabletAndMobileContainer>
                  </>
                )}
              </ConditionalWrapper>
            </ConditionalWrapper>
          )}
          {description && (
            <div className="text" dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
      </div>
      <VideoModal
        title={title}
        videoUrl={videoUrl}
        selectFunction={setSelectedContent}
        isActive={!!selectedContentGuid}
      />
    </>
  );
};

Item.defaultProps = {
  guid: null,
  altText: null,
  description: null,
  image: {},
  link: null,
  target: null,
  title: null,
  videoUrl: null,
  playIconColor: null,
  animation: {}
};

Item.propTypes = {
  guid: PropTypes.string,
  altText: PropTypes.string,
  description: PropTypes.string,
  image: sizedImageShape,
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  videoUrl: PropTypes.string,
  playIconColor: PropTypes.string,
  animation: animationShape
};

export default Item;
