import React from 'react';

import WhiteLabelSmallHeroBanner from './WhiteLabelSmallHeroBanner';
import { whiteLabelSmallHeroBanner } from '../../../types';

const WhiteLabelSmallHeroBannerWidget = ({ heroSlide }) => {
  return (
    <section className="hero hero-small">
      <WhiteLabelSmallHeroBanner heroSlide={heroSlide} />
    </section>
  );
};

WhiteLabelSmallHeroBannerWidget.propTypes = {
  heroSlide: whiteLabelSmallHeroBanner.isRequired
};

export default WhiteLabelSmallHeroBannerWidget;
