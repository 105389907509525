import { SET_IS_SCROLL_PAST_NAV, SET_IS_SCROLL_PAST_FOOTER } from '../actions/general.actions';

export default function general(state = null, action) {
  switch (action.type) {
    case SET_IS_SCROLL_PAST_NAV:
      return {
        ...state,
        isScrollPastNav: action.isScrollPastNav
      };
    case SET_IS_SCROLL_PAST_FOOTER:
      return {
        ...state,
        isScrollPastFooter: action.isScrollPastFooter
      };
    default:
      return state;
  }
}
