import React from 'react';
import PropTypes from 'prop-types';

const SliderArrow = ({ className, to, onClick, img, currentSlide, checkIfHidden }) => {
  if (checkIfHidden(currentSlide)) return false;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
      aria-label={to}
    >
      <img className="icon" src={img} alt="arrow" />
    </button>
  );
};

SliderArrow.defaultProps = {
  onClick: null,
  currentSlide: 0,
  checkIfHidden: () => false
};

SliderArrow.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  img: PropTypes.string.isRequired,
  currentSlide: PropTypes.number,
  checkIfHidden: PropTypes.func
};

export default SliderArrow;
