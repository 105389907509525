import React from 'react';
import PropTypes from 'prop-types';

const ParagraphItem = ({ description, title }) => {
  return (
    <div className="item">
      <span className="eyebrow-text">{title}</span>
      <div className="inner-content" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

ParagraphItem.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ParagraphItem;
