import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import close from '../../../images/icons/layout/Close-Blue.svg';
import arrowLeft from '../../../images/icons/layout/Arrow-Left-Blue.svg';
import {
  OVERVIEW,
  LEARN_MORE_LINK,
  NEWSROOM_READ_MORE,
  BACK_BUTTON
} from '../../../constants/expansion-titles';
import { newsroomInfoShape } from '../../../types';
import LazyLoadImg from '../shared/LazyLoadImg';

const BusinessUnitModalExpansion = ({
  isActive,
  selectBusinessUnit,
  title,
  statement,
  overviewIcon,
  overview,
  learnMoreLink,
  learnMoreTarget,
  resourceLinksIcon,
  resourceLinksTitle,
  resourceLinks,
  newsroomLink,
  newsroomInfo
}) => {
  ReactModal.setAppElement('#wrapper');

  return (
    <ReactModal
      isOpen={isActive}
      className="mobile-modal"
      overlayClassName="mobile-modal-overlay br-sm"
      bodyOpenClassName="ReactModal__Body--open-desktop-scroll"
    >
      <button type="button" onClick={() => selectBusinessUnit()} className="close-button">
        <img src={close} alt="Close" />
      </button>
      <div className="tile">
        <div className="content active">
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: statement }} />
        </div>
        <span className="triangle large triangle-no-border active" />
        <div className="tile-expansion">
          <div className="heading">
            {overviewIcon && <img src={overviewIcon} alt="Overview Icon" />}
            <h3>{OVERVIEW}</h3>
          </div>
          <div className="inner-content" dangerouslySetInnerHTML={{ __html: overview }} />
          <a
            href={learnMoreLink}
            {...learnMoreTarget && { target: learnMoreTarget }}
            title="Learn More"
          >
            {LEARN_MORE_LINK}
          </a>
          <div className="heading">
            {resourceLinksIcon && <img src={resourceLinksIcon} alt="Products Icon" />}
            <h3>{resourceLinksTitle}</h3>
          </div>
          {resourceLinks &&
            resourceLinks.map(link => (
              <a key={link.name} target={link.target} href={link.url} title={`Go to ${link.name}`}>
                {link.name}
              </a>
            ))}
          {newsroomInfo && (
            <>
              <h3 className="heading">{newsroomInfo.contentType}</h3>
              <LazyLoadImg src={newsroomInfo.thumbnailImage} alt="Newsroom Article" />
              <h4 className="newsroom-title">{newsroomInfo.title}</h4>
              <a className="newsroom-link" href={newsroomLink} title="Newsroom Link">
                {NEWSROOM_READ_MORE}
              </a>
            </>
          )}
        </div>
        <button type="button" onClick={() => selectBusinessUnit()} className="btn-no-style">
          <div className="btn-back">
            <img src={arrowLeft} alt="Back Arrow" />
            <p>{BACK_BUTTON}</p>
          </div>
        </button>
      </div>
    </ReactModal>
  );
};

BusinessUnitModalExpansion.defaultProps = {
  isActive: false,
  overviewIcon: '',
  resourceLinksIcon: '',
  resourceLinksTitle: '',
  resourceLinks: [],
  newsroomLink: '',
  newsroomInfo: {}
};

BusinessUnitModalExpansion.propTypes = {
  isActive: PropTypes.bool,
  selectBusinessUnit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  statement: PropTypes.string.isRequired,
  overviewIcon: PropTypes.string,
  overview: PropTypes.string.isRequired,
  learnMoreLink: PropTypes.string.isRequired,
  learnMoreTarget: PropTypes.string.isRequired,
  resourceLinksIcon: PropTypes.string,
  resourceLinksTitle: PropTypes.string,
  resourceLinks: PropTypes.arrayOf(PropTypes.object),
  newsroomLink: PropTypes.string,
  newsroomInfo: newsroomInfoShape
};

export default BusinessUnitModalExpansion;
