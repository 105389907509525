export default function getQueryStringParameters() {
  return (async () => {
    const additionSymbol = /\+/g;
    const search = /([^&=]+)=?([^&]*)/g;
    const decodedUriComponent = function getDecodedUriComponent(encodedUriComponent) {
      return decodeURIComponent(encodedUriComponent.replace(additionSymbol, ' '));
    };

    const urlParameters = {};
    const queryString = window.location.search.substring(1);
    let match = search.exec(queryString);
    while (match !== null) {
      urlParameters[decodedUriComponent(match[1])] = decodedUriComponent(match[2]);
      match = search.exec(queryString);
    }

    return urlParameters;
  })();
}
