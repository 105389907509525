import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import GlobalComponentContainer from '../GlobalComponentContainer';
import ContentDivider from '../shared/ContentDivider';
import DesktopAndTabletContainer from '../../shared/DesktopAndTabletContainer';
import MobileContainer from '../../shared/MobileContainer';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import LazyLoadImg from '../shared/LazyLoadImg';

import { sizedImageShape } from '../../../types';

const FeaturedContentImageComponent = ({
  altText,
  featuredImage,
  featuredImageMobile,
  featuredText,
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="featured-image section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
            <MobileContainer>
              <LazyLoadImg src={featuredImageMobile} alt={altText || title} />
            </MobileContainer>
            <DesktopAndTabletContainer>
              <LazyLoadImg src={featuredImage} alt={altText || title} />
            </DesktopAndTabletContainer>
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: featuredText }} />
            </div>
          </ConditionalWrapper>
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

FeaturedContentImageComponent.defaultProps = {
  altText: '',
  hasContentDivider: false,
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  shortDescription: ''
};

FeaturedContentImageComponent.propTypes = {
  altText: PropTypes.string,
  featuredImage: sizedImageShape.isRequired,
  featuredImageMobile: sizedImageShape.isRequired,
  featuredText: PropTypes.string.isRequired,
  hasContentDivider: PropTypes.bool,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default FeaturedContentImageComponent;
