import React, { useRef } from 'react';

import { smallHeroSlide } from '../../../types';
import CtaButton from '../../shared/ctaButton/CtaButton';
import { useCssVariables } from '../../../utilities';

const SmallHeroSlide = ({ heroSlide }) => {
  const hasCtaButton = !!heroSlide.ctaButton.path;
  const hasSecondCtaButton = !!heroSlide.ctaButton.secondPath;

  const colorWrapperRef = useRef(null);

  useCssVariables(colorWrapperRef, {
    '--background-color': heroSlide.backgroundColor,
    '--text-color': heroSlide.textColor
  });

  return (
    <div className="item">
      <div ref={colorWrapperRef} className="color-wrapper">
        <div className="content">
          <div className="container-fluid">
            <div className="inner-content">
              <p
                className="eyebrow-text"
                dangerouslySetInnerHTML={{ __html: heroSlide.eyebrowText }}
              />
              <h1>
                <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
              </h1>
            </div>
            {hasCtaButton && (
              <div className="inner-cta button">
                <CtaButton
                  backgroundColor={heroSlide.ctaButton.backgroundColor}
                  path={heroSlide.ctaButton.path}
                  target={heroSlide.ctaButton.target}
                  textColor={heroSlide.ctaButton.textColor}
                >
                  {heroSlide.ctaButton.text}
                </CtaButton>
                {hasSecondCtaButton && (
                  <CtaButton
                    className="second"
                    backgroundColor={heroSlide.ctaButton.secondBackgroundColor}
                    path={heroSlide.ctaButton.secondPath}
                    target={heroSlide.ctaButton.secondTarget}
                    textColor={heroSlide.ctaButton.secondTextColor}
                  >
                    {heroSlide.ctaButton.secondText}
                  </CtaButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SmallHeroSlide.propTypes = {
  heroSlide: smallHeroSlide.isRequired
};

export default SmallHeroSlide;
