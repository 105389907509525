import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import closeBlue from 'images/icons/layout/Close-Blue.svg';
import closeWhite from 'images/icons/layout/Close-White.svg';
import hamburgerWhite from 'images/icons/layout/Hamburger.svg';
import hamburgerBlue from 'images/icons/layout/Hamburger-Blue.svg';
import searchWhite from 'images/icons/layout/Search.svg';
import searchBlue from 'images/icons/layout/Search-Blue.svg';
import Alerts from '../shared/alerts/Alerts';

import Footer from './Footer';
import MobileMenuItem from './shared/MobileMenuItem';
import { globalImage } from '../../types';

const MobileNavigationMenu = ({
  alerts,
  copyright,
  corporateAddress,
  footerMenuItems,
  globalImages,
  hasAlerts,
  hasStickyNav,
  isScrollPastNav,
  legalMenuItems,
  mainMenuItems,
  searchUrl,
  isWhiteLabel
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isNavInverted = () => !isScrollPastNav && !isMenuVisible && hasStickyNav;

  const toggleMenu = () => {
    if (isMenuVisible) {
      document.body.classList.remove('nav-open');
    } else {
      document.body.classList.add('nav-open');
    }

    setIsMenuVisible(!isMenuVisible);
  };

  const getMenuToggleIcon = () => {
    if (isMenuVisible && !hasStickyNav) {
      return closeWhite;
    }

    if (isMenuVisible && hasStickyNav) {
      return closeBlue;
    }

    if (isNavInverted() || !hasStickyNav) {
      return hamburgerWhite;
    }
    return hamburgerBlue;
  };

  return (
    <nav
      className={`nav${
        isNavInverted() && hasStickyNav ? ' inverse' : !hasStickyNav ? ' relative inverse' : ''
      }${isMenuVisible ? ' expanded' : ''} ${isWhiteLabel ? 'white-label' : ''}`}
      id="nav-mobile"
      aria-label="Mobile Navigation Menu"
    >
      {hasAlerts && <Alerts alerts={alerts} />}
      <div className="container-fluid">
        <div className="nav-wrap">
          <div className="logo logo-crossfade">
            <a href="/" title="Go to Home">
              <img
                className={`${isNavInverted() || !hasStickyNav ? 'transparent' : ''}`}
                id="bottom-logo-mobile"
                alt="Trustmark Logo"
                src={globalImages.logo && globalImages.logo.directUrl}
              />
              <img
                className={`${isNavInverted() || !hasStickyNav ? '' : 'transparent'}`}
                id="top-logo-mobile"
                alt="Trustmark Logo Inverse"
                src={globalImages.logoInverse && globalImages.logoInverse.directUrl}
              />
            </a>
          </div>
          {!isWhiteLabel && (
            <ul className="nav-cta">
              <li className="search">
                <a href={searchUrl} title="Go to search" className="search">
                  <img
                    src={isNavInverted() || !hasStickyNav ? searchWhite : searchBlue}
                    alt="Search Icon"
                  />
                </a>
              </li>
              <li className="menu-toggle">
                <button onClick={toggleMenu} className="btn-no-style" type="button">
                  <img src={getMenuToggleIcon()} alt="Mobile Menu" />
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      {!isWhiteLabel && (
        <AnimateHeight duration={500} height={isMenuVisible ? 'auto' : 0} animateOpacity>
          <ul className="primary-navigation">
            {mainMenuItems &&
              mainMenuItems.map(item => (
                <MobileMenuItem key={item.name} {...item} businessUnits={item.businessUnits} />
              ))}
          </ul>
          <section className="footer-links">
            <Footer
              ccpaBanner={null}
              copyright={copyright}
              corporateAddress={corporateAddress}
              footerMenuItems={footerMenuItems}
              globalImages={globalImages}
              isMobileNavigation
              legalMenuItems={legalMenuItems}
            />
          </section>
        </AnimateHeight>
      )}
    </nav>
  );
};

MobileNavigationMenu.defaultProps = {
  alerts: [],
  searchUrl: ''
};

MobileNavigationMenu.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string.isRequired,
  corporateAddress: PropTypes.string.isRequired,
  footerMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hasAlerts: PropTypes.bool.isRequired,
  hasStickyNav: PropTypes.bool.isRequired,
  isScrollPastNav: PropTypes.bool.isRequired,
  legalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchUrl: PropTypes.string,
  isWhiteLabel: PropTypes.bool.isRequired
};

export default MobileNavigationMenu;
