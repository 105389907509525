import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import GlobalComponentContainer from '../GlobalComponentContainer';
import ContentDivider from '../shared/ContentDivider';
import Highlight from './Highlight';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

const HighlightsComponent = ({
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  hasContentDivider,
  highlights
}) => {
  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="highlights-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            {title && (
              <GlobalComponentContainer
                {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
              />
            )}
            <div className="highlights-container">
              {Children.toArray(highlights.map(highlight => <Highlight {...highlight} />))}
            </div>
            {hasContentDivider && <ContentDivider />}
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

HighlightsComponent.defaultProps = {
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  title: null,
  highlights: []
};

HighlightsComponent.propTypes = {
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  hasContentDivider: PropTypes.bool.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.object)
};

export default HighlightsComponent;
