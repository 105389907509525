import React from 'react';
import PropTypes from 'prop-types';
import htmlToPlainText from 'html2plaintext';

const JobRow = ({
  applyUrl,
  businessUnit,
  category,
  jobDescription,
  jobID,
  jobLocation,
  rank,
  submit,
  title
}) => {
  const descriptionCharacterCount = 300;
  let description = htmlToPlainText(jobDescription);

  if (description.length > descriptionCharacterCount) {
    description = `${description.slice(0, descriptionCharacterCount)}...`;
  }

  return (
    <div className="table-row content">
      <div className="item">
        <span className="item-inner">
          <button
            className="btn btn-no-style title"
            type="button"
            onClick={() =>
              submit({
                applyUrl,
                rank,
                searchItemId: jobID
              })
            }
          >
            {title}
          </button>
          <p className="business-unit">{businessUnit}</p>
        </span>
      </div>
      <div className="item">
        <span className="item-inner">
          <p>{category}</p>
        </span>
      </div>
      <div className="item">
        <span className="item-inner">
          <p>{jobLocation}</p>
        </span>
      </div>
      <div className="item half last-item">
        <span className="item-inner">
          <button
            className="btn"
            type="button"
            onClick={() =>
              submit({
                applyUrl,
                rank,
                searchItemId: jobID
              })
            }
          >
            Apply
          </button>
        </span>
      </div>
      <div className="item full">
        <p>DESCRIPTION</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

JobRow.propTypes = {
  applyUrl: PropTypes.string.isRequired,
  businessUnit: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  jobDescription: PropTypes.string.isRequired,
  jobID: PropTypes.string.isRequired,
  jobLocation: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default JobRow;
