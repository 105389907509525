import React from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';

import { ccpaBannerShape } from '../../../types';

const CCPABanner = ({ ccpaBanner, setHasCCPABanner }) => {
  const onDismiss = () => {
    cookie.save('CCPA Banner', null, {
      path: '/',
      expires: new Date(new Date().getFullYear() + 10, 0, 1)
    });
  };

  return (
    <section className="section-main border-top fixed full-width">
      <div className="container-fluid">
        <div className="ccpa-banner">
          <div dangerouslySetInnerHTML={{ __html: ccpaBanner.text }} />
          <button
            className="btn btn-primary-alt"
            type="button"
            onClick={() => {
              onDismiss();
              setHasCCPABanner(false);
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </section>
  );
};

CCPABanner.defaultProps = {
  ccpaBanner: {},
  setHasCCPABanner: () => {}
};

CCPABanner.propTypes = {
  ccpaBanner: ccpaBannerShape,
  setHasCCPABanner: PropTypes.func
};

export default CCPABanner;
