import React, { useState } from 'react';
import PropTypes from 'prop-types';

import searchWhite from 'images/icons/layout/Search.svg';
import searchBlue from 'images/icons/layout/Search-Blue.svg';
import Alerts from '../shared/alerts/Alerts';
import DesktopMenuItem from './shared/DesktopMenuItem';
import { globalImage } from '../../types';

const DesktopNavigationMenu = ({
  alerts,
  currentUrl,
  mainMenuItems,
  globalImages,
  hasAlerts,
  hasStickyNav,
  isScrollPastNav,
  searchUrl,
  isWhiteLabel
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isNavInverted = () => !isScrollPastNav && !isHovered && hasStickyNav;

  return (
    <nav
      onMouseEnter={() => !isScrollPastNav && setIsHovered(true)}
      onMouseLeave={() => !isScrollPastNav && setIsHovered(false)}
      className={`nav${
        isNavInverted() && hasStickyNav ? ' inverse' : !hasStickyNav ? ' relative inverse' : ''
      } ${isWhiteLabel ? 'white-label' : ''}`}
      id="nav-desktop"
      aria-label="Desktop Navigation Menu"
    >
      {hasAlerts && <Alerts alerts={alerts} />}
      <div className="inner">
        <div className="container-fluid">
          <div className="nav-wrap">
            <div className="logo logo-crossfade">
              <a href="/" title="Go to Home">
                <img
                  className={`${isNavInverted() || !hasStickyNav ? 'transparent' : ''}`}
                  id="bottom-logo-desktop"
                  alt="Trustmark Logo"
                  src={globalImages.logo && globalImages.logo.directUrl}
                />
                <img
                  className={`${isNavInverted() || !hasStickyNav ? '' : 'transparent'}`}
                  id="top-logo-desktop"
                  alt="Trustmark Logo Inverse"
                  src={globalImages.logoInverse && globalImages.logoInverse.directUrl}
                />
              </a>
            </div>
            {!isWhiteLabel && (
              <ul className="primary-navigation">
                {mainMenuItems &&
                  mainMenuItems.map(item => (
                    <DesktopMenuItem
                      currentUrl={currentUrl}
                      key={item.name}
                      {...item}
                      businessUnits={item.businessUnits}
                    />
                  ))}
                <li className="search">
                  <a href={searchUrl} title="Go to search">
                    <img
                      src={isNavInverted() || !hasStickyNav ? searchWhite : searchBlue}
                      alt="Search Icon"
                    />
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

DesktopNavigationMenu.defaultProps = {
  alerts: [],
  searchUrl: ''
};

DesktopNavigationMenu.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  currentUrl: PropTypes.string.isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  isScrollPastNav: PropTypes.bool.isRequired,
  hasAlerts: PropTypes.bool.isRequired,
  hasStickyNav: PropTypes.bool.isRequired,
  searchUrl: PropTypes.string,
  isWhiteLabel: PropTypes.bool.isRequired
};

export default DesktopNavigationMenu;
