import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import classNames from 'classnames';
import { useCssVariables } from '../../../utilities';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import LazyLoadImg from '../shared/LazyLoadImg';
import logConversion from '../../../services/conversion.service';

import { sizedImageShape } from '../../../types';

const ConversionLinkComponent = ({
  alignment,
  altText,
  backgroundColor,
  contentTitle,
  conversionIdentifier,
  ctaButtonBackgroundColor,
  ctaButtonTarget,
  ctaButtonText,
  ctaButtonTextColor,
  ctaButtonUrl,
  description,
  image,
  imagePosition,
  imageUrl,
  imageUrlTarget,
  prettyUrlTitle,
  title,
  titleTextColor
}) => {
  const logConversionAndRoute = () => {
    logConversion(conversionIdentifier);

    if (ctaButtonTarget === '_self' || ctaButtonTarget === '') {
      window.location.href = ctaButtonUrl;
    } else {
      window.open(ctaButtonUrl, '_blank');
    }
  };
  const hasImage = image && image.url;

  const conversionLinkComponentRef = useRef(null);

  useCssVariables(conversionLinkComponentRef, {
    '--background-color': `${backgroundColor || 'unset'}`,
    '--text-color': `${titleTextColor || 'unset'}`,
    '--cta-background-color': `${ctaButtonBackgroundColor || 'unset'}`,
    '--cta-text-color': `${ctaButtonTextColor || 'unset'}`
  });

  return (
    <Element name={prettyUrlTitle}>
      <section
        ref={conversionLinkComponentRef}
        className="section-white conversion-link-component section-gutter"
      >
        <div className="general-text-component">
          <div className="container-fluid">
            {contentTitle && <h2>{contentTitle}</h2>}
            <div className={classNames('description-container', imagePosition || 'left')}>
              {description && (
                <div className="content" dangerouslySetInnerHTML={{ __html: description }} />
              )}
              {hasImage && (
                <div className="image">
                  <ConditionalWrapper
                    condition={imageUrl}
                    wrapper={children => (
                      <a
                        href={imageUrl}
                        target={imageUrlTarget}
                        title={`Link for ${altText || title}`}
                      >
                        {children}
                      </a>
                    )}
                  >
                    <LazyLoadImg src={image} alt={altText || title} />
                  </ConditionalWrapper>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="quick-cta">
          <div className={`container-fluid ${alignment}`}>
            <div className={classNames('wrapper', { inner: title })}>
              {title && <div className="content" dangerouslySetInnerHTML={{ __html: title }} />}

              <div className="cta">
                <div className="btn-container">
                  <button className="btn" onClick={() => logConversionAndRoute()} type="button">
                    {ctaButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

ConversionLinkComponent.defaultProps = {
  altText: null,
  contentTitle: '',
  ctaButtonTarget: '',
  description: '',
  image: {},
  imageUrl: '',
  imageUrlTarget: '',
  prettyUrlTitle: ''
};

ConversionLinkComponent.propTypes = {
  altText: PropTypes.string,
  alignment: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  contentTitle: PropTypes.string,
  conversionIdentifier: PropTypes.string.isRequired,
  ctaButtonBackgroundColor: PropTypes.string.isRequired,
  ctaButtonTarget: PropTypes.string,
  ctaButtonText: PropTypes.string.isRequired,
  ctaButtonTextColor: PropTypes.string.isRequired,
  ctaButtonUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: sizedImageShape,
  imagePosition: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageUrlTarget: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleTextColor: PropTypes.string.isRequired
};

export default ConversionLinkComponent;
