import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { Element, scroller } from 'react-scroll';

import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import { careersSearch, submitClick } from '../../../services/careersSearch.service';
import ContentDivider from '../shared/ContentDivider';
import Loader from '../../shared/loader/Loader';
import Pager from '../../shared/pager/Pager';
import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';

import JobRow from './JobRow';
import GlobalComponentContainer from '../GlobalComponentContainer';
import { globalComponentProperties, globalComponentDefaults } from '../../../types';
import CareersSearchBar from './CareersSearchBar';

const CareersComponent = ({
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title
}) => {
  const pageOptions = [25, 50];
  const zipOptions = [10, 25, 50, 100];
  const [filters, setFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState();
  const [currentQuery, setCurrentQuery] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [pagerInfo, setPagerInfo] = useState({});
  const [currentItemsPerPage, setItemsPerPage] = useState(pageOptions[0]);
  const [jobs, setJobs] = useState();
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  const [isPagingExpanded, setIsPagingExpanded] = useState(false);
  const [isGeolocationExpanded, setIsGeolocationExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchId, setSearchId] = useState();
  const [currentRange, setCurrentRange] = useState();
  const [currentZip, setCurrentZip] = useState();

  const getCareersNoLoading = async (query, page, filter, itemsPerPage, zipRange, zip) => {
    const response = await careersSearch(query, filter, page, itemsPerPage, zipRange, zip);

    setJobs(response.listing.items);
    setFilters(response.filters);
    setPagerInfo(response.listing.pager);
    setCurrentQuery(query);
    setSearchId(response.searchId);
  };

  const getCareers = async (query, page, filter, itemsPerPage, zipRange, zip) => {
    setIsLoading(true);
    await getCareersNoLoading(query, page, filter, itemsPerPage, zipRange, zip);

    scroller.scrollTo('scroll', {
      smooth: true,
      duration: 500,
      delay: 150,
      offset: -150
    });

    setIsLoading(false);
  };

  const changePage = (query, page) => {
    getCareers(query, page, appliedFilters, currentItemsPerPage, currentRange, currentZip);
  };

  const toggleFilters = () => {
    setIsFiltersExpanded(!isFiltersExpanded);
    setIsPagingExpanded(false);
    setIsGeolocationExpanded(false);
  };

  const togglePaging = () => {
    setIsPagingExpanded(!isPagingExpanded);
    setIsFiltersExpanded(false);
    setIsGeolocationExpanded(false);
  };

  const toggleGeolocation = () => {
    setIsGeolocationExpanded(!isGeolocationExpanded);
    setIsPagingExpanded(false);
    setIsFiltersExpanded(false);
  };

  const modifyFilters = filter => {
    const item = appliedFilters.find(applied => applied.key === filter.key);
    if (item) {
      const valueIndex = item.values.indexOf(filter.value);

      if (valueIndex !== -1) {
        // Remove value from existing array of values
        item.values.splice(valueIndex, 1);
      } else {
        // Add value to the existing array of values
        item.values.push(filter.value);
      }
    } else {
      // Add new object with key and value
      appliedFilters.push({
        key: filter.key,
        values: [filter.value]
      });
    }

    const count = appliedFilters
      .map(appliedFilter => appliedFilter.values.length)
      .reduce((a, b) => a + b, 0);

    setAppliedFilters(appliedFilters);
    setAppliedFiltersCount(count);
    getCareers(currentQuery, 1, appliedFilters, currentItemsPerPage, currentRange, currentZip);
  };

  const clearFilters = () => {
    setAppliedFilters([]);
    setAppliedFiltersCount(null);
    setCurrentZip();
    setCurrentRange();
    getCareers(currentQuery, 1, [], currentItemsPerPage);
  };

  const search = async query => {
    await getCareers(query, 1, appliedFilters, currentItemsPerPage, currentRange, currentZip);
  };

  const setZip = value => {
    setCurrentZip(value);
    if (value.length === 5 && currentRange) {
      getCareers(currentQuery, 1, appliedFilters, currentItemsPerPage, currentRange, value);
    }
  };

  const setRange = value => {
    setCurrentRange(value);
    toggleGeolocation();

    if (currentZip && currentZip.length === 5) {
      getCareers(currentQuery, 1, appliedFilters, currentItemsPerPage, value, currentZip);
    }
  };

  const submitClickAndNavigate = item => {
    const newItem = item;
    newItem.searchId = searchId;

    submitClick(newItem);

    window.open(item.applyUrl);
  };

  if (!pageLoaded) {
    setPageLoaded(true);
    getCareersNoLoading('*', 1, appliedFilters, currentItemsPerPage);
  }

  return (
    <section className="section-white">
      <div className="careers-landing section-gutter">
        {isLoading && <Loader />}
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <Element name="scroll" />

          <div className="container-fluid">
            <GlobalComponentContainer
              parentPageTitle={parentPageTitle}
              parentPageUrl={parentPageUrl}
              shortDescription={shortDescription}
              title={title}
            />
          </div>

          <section className="careers-selection">
            <section className="util">
              <div className="filters">
                <div className="container-fluid">
                  <div className="filters-container full-width">
                    <CareersSearchBar getCareers={search} />
                    <div className="accordion-list">
                      <ul className="accordion filter">
                        <li className={`${isFiltersExpanded ? ' active' : ''}`}>
                          <button className="btn-no-style" type="button" onClick={toggleFilters}>
                            <span className="btn-contain">
                              {appliedFiltersCount && appliedFiltersCount !== 0
                                ? appliedFiltersCount === 1
                                  ? '1 Filter'
                                  : `${appliedFiltersCount} Filters`
                                : 'Filters'}
                            </span>
                            <img
                              src={isFiltersExpanded ? arrowUp : arrowDown}
                              className="chevron"
                              alt="Chevron Arrow"
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="geolocation">
                      <input
                        placeholder="Zip Code"
                        type="number"
                        maxLength="5"
                        value={currentZip || ''}
                        onChange={event => setZip(event.target.value)}
                      />
                      <ul className="accordion">
                        <li className={`${isGeolocationExpanded ? ' active' : ''}`}>
                          <button
                            className="btn-no-style"
                            type="button"
                            onClick={toggleGeolocation}
                          >
                            {currentRange ? (
                              <span className="btn-contain">{`${currentRange} Miles`}</span>
                            ) : (
                              <span className="btn-contain">Any Distance</span>
                            )}

                            <img
                              src={isGeolocationExpanded ? arrowUp : arrowDown}
                              className="chevron"
                              alt="Chevron Arrow"
                            />
                          </button>
                        </li>
                        <AnimateHeight
                          duration={500}
                          height={isGeolocationExpanded ? 'auto' : 0}
                          animateOpacity
                          className="expanded-filters-list"
                        >
                          <ul className="filters-list">
                            {zipOptions.map(zip => (
                              <li key={zip}>
                                <button
                                  className="btn-no-style"
                                  type="button"
                                  onClick={() => setRange(zip)}
                                >
                                  {`${zip} Miles`}
                                </button>
                              </li>
                            ))}
                            <li>
                              <button
                                className="btn-no-style"
                                type="button"
                                onClick={() => setRange()}
                              >
                                Any Distance
                              </button>
                            </li>
                          </ul>
                        </AnimateHeight>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <AnimateHeight
                duration={500}
                height={isFiltersExpanded ? 'auto' : 0}
                animateOpacity
                className="expanded-filters-list"
              >
                <div className="container-fluid">
                  <div className="filters-list">
                    {filters &&
                      filters.map(filter => (
                        <div className="form-group" key={filter.label}>
                          <span className="section-header">{filter.label}</span>
                          {filter.items.map(item => (
                            <div className="form-group" key={item.value}>
                              <input
                                type="checkbox"
                                checked={item.isSelected}
                                onChange={() => {
                                  modifyFilters(item);
                                }}
                              />
                              <label>{`${item.value} (${item.count})`}</label>
                            </div>
                          ))}
                        </div>
                      ))}
                    <div className="reset">
                      <button
                        className="btn-no-style as-link"
                        type="button"
                        onClick={() => clearFilters()}
                      >
                        Reset Filters
                      </button>
                    </div>
                  </div>
                </div>
              </AnimateHeight>
            </section>
          </section>
          <div className="container-fluid">
            <section className="careers-sub-selection">
              <div className="geolocation">
                <input
                  placeholder="Zip Code"
                  type="number"
                  maxLength="5"
                  value={currentZip || ''}
                  onChange={event => setZip(event.target.value)}
                />
                <ul className="accordion">
                  <li className={`${isGeolocationExpanded ? ' active' : ''}`}>
                    <button className="btn-no-style" type="button" onClick={toggleGeolocation}>
                      {currentRange ? (
                        <span className="btn-contain">{`${currentRange} Miles`}</span>
                      ) : (
                        <span className="btn-contain">Any Distance</span>
                      )}

                      <img
                        src={isGeolocationExpanded ? arrowUp : arrowDown}
                        className="chevron"
                        alt="Chevron Arrow"
                      />
                    </button>
                  </li>
                  <AnimateHeight
                    duration={500}
                    height={isGeolocationExpanded ? 'auto' : 0}
                    animateOpacity
                    className="expanded-filters-list"
                  >
                    <ul className="filters-list">
                      {zipOptions.map(zip => (
                        <li key={zip}>
                          <button
                            className="btn-no-style"
                            type="button"
                            onClick={() => setRange(zip)}
                          >
                            {`${zip} Miles`}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button className="btn-no-style" type="button" onClick={() => setRange()}>
                          Any Distance
                        </button>
                      </li>
                    </ul>
                  </AnimateHeight>
                </ul>
              </div>
              <div className="paging">
                <div className="accordion-list">
                  <div className="title">
                    <p>Items per Page</p>
                  </div>
                  <ul className="accordion">
                    <li className={`${isPagingExpanded ? ' active' : ''}`}>
                      <button className="btn-no-style" type="button" onClick={togglePaging}>
                        <span className="btn-contain">{currentItemsPerPage}</span>
                        <img
                          src={isPagingExpanded ? arrowUp : arrowDown}
                          className="chevron"
                          alt="Chevron Arrow"
                        />
                      </button>
                    </li>
                    <AnimateHeight
                      duration={500}
                      height={isPagingExpanded ? 'auto' : 0}
                      animateOpacity
                      className="expanded-filters-list"
                    >
                      <ul className="filters-list">
                        {pageOptions.map(value => (
                          <li key={value}>
                            <button
                              className="btn-no-style"
                              type="button"
                              onClick={() => {
                                setItemsPerPage(value);
                                togglePaging();
                                getCareers(
                                  currentQuery,
                                  1,
                                  appliedFilters,
                                  value,
                                  currentRange,
                                  currentZip
                                );
                              }}
                            >
                              {value}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </AnimateHeight>
                  </ul>
                </div>
              </div>
            </section>
          </div>
          {jobs && (
            <section className="careers-results">
              <div className="container-fluid">
                <div className="results-grid">
                  {jobs.length === 0 ? (
                    <h2>No Results Found</h2>
                  ) : (
                    <>
                      <div className="table-row header">
                        <div className="item">JOBS</div>
                        <div className="item">CATEGORY</div>
                        <div className="item">LOCATION</div>
                      </div>
                      {jobs.map((job, index) => (
                        <JobRow
                          key={job.applyUrl}
                          rank={index + (pagerInfo.currentPage - 1) * currentItemsPerPage}
                          submit={submitClickAndNavigate}
                          {...job}
                        />
                      ))}
                    </>
                  )}
                </div>
                {jobs.length !== 0 && (
                  <Pager
                    search={changePage}
                    searchText={currentQuery}
                    currentPage={pagerInfo.currentPage}
                    pagerInfo={pagerInfo}
                  />
                )}
              </div>
            </section>
          )}
          {hasContentDivider && (
            <div className="container-fluid">
              <ContentDivider />
            </div>
          )}
        </ConditionalWrapper>
      </div>
    </section>
  );
};

CareersComponent.defaultProps = {
  ...globalComponentDefaults,
  hasContentDivider: false,
  prettyUrlTitle: ''
};

CareersComponent.propTypes = {
  ...globalComponentProperties,
  hasContentDivider: PropTypes.bool,
  prettyUrlTitle: PropTypes.string
};

export default CareersComponent;
