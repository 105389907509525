import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { scroller, Element } from 'react-scroll';
import storeProvider from '../../shared/StoreProvider';

import ConditionalWrapper from '../../shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '../GlobalComponentContainer';
import PrivacyPolicy from './PrivacyPolicy';
import ContentDivider from '../shared/ContentDivider';

const PrivacyPolicyComponent = ({
  hasAlerts,
  isScrollPastFooter,
  isScrollPastNav,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  privacyPolicies,
  queryStringValue,
  shortDescription,
  title
}) => {
  const [selectValue, setSelectValue] = useState('Jump To');
  const [pageLoaded, setPageLoaded] = useState(false);
  const anchorSettings = {
    duration: 500,
    delay: 150,
    smooth: true,
    offset: hasAlerts ? -220 : -140
  };

  const handleOnChange = value => {
    setSelectValue(value);
    scroller.scrollTo(value, anchorSettings);
  };

  const scrollToTop = () => {
    scroller.scrollTo('wrapper', anchorSettings);
  };

  const handleOnLoad = async value => {
    await setSelectValue(value);
    await handleOnChange(value);
  };

  if (!pageLoaded) {
    setPageLoaded(true);
    handleOnLoad(queryStringValue);
  }

  return (
    <section className="section-white">
      <div className="container-fluid">
        <div className="privacy-policy-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={children => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <div className="privacy-policy-container">
              {title && (
                <GlobalComponentContainer
                  {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
                />
              )}
            </div>
          </ConditionalWrapper>
        </div>
        <ContentDivider />
        <div className="privacy-policy-component section-gutter">
          <div className="privacy-policy-container">
            <div className="form-group select-input" id="select">
              <select
                onChange={event => {
                  handleOnChange(event.target.value);
                }}
                value={selectValue}
              >
                <option defaultValue selected hidden>
                  Jump To
                </option>
                {privacyPolicies.map(privacyPolicy => (
                  <option
                    key={privacyPolicy.title}
                    value={privacyPolicy.title.replace(/\s+/g, '-').toLowerCase()}
                  >
                    {privacyPolicy.prettyUrlTitle || privacyPolicy.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="privacy-policy-grid">
              {privacyPolicies.map(privacyPolicy => (
                <Element
                  name={
                    privacyPolicy.prettyUrlTitle ||
                    privacyPolicy.title.replace(/\s+/g, '-').toLowerCase()
                  }
                  key={privacyPolicy.title}
                >
                  <PrivacyPolicy
                    description={privacyPolicy.description}
                    id={privacyPolicy.title.replace(/\s+/g, '-').toLowerCase()}
                    title={privacyPolicy.title}
                  />
                </Element>
              ))}
              <button
                type="button"
                className={`btn btn-styled to-top ${isScrollPastNav ? '' : 'btn-hidden'}${
                  isScrollPastFooter ? ' btn-relative' : ''
                }`}
                onClick={() => {
                  scrollToTop();
                }}
              >
                Back To Top
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PrivacyPolicyComponent.defaultProps = {
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  privacyPolicies: '',
  queryStringValue: '',
  shortDescription: null,
  title: null
};

PrivacyPolicyComponent.propTypes = {
  hasAlerts: PropTypes.bool.isRequired,
  isScrollPastNav: PropTypes.bool.isRequired,
  isScrollPastFooter: PropTypes.bool.isRequired,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  privacyPolicies: PropTypes.arrayOf(PropTypes.object),
  queryStringValue: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isScrollPastNav: !!(state.general && state.general.isScrollPastNav),
    isScrollPastFooter: !!(state.general && state.general.isScrollPastFooter)
  };
}

export default storeProvider(connect(mapStateToProps)(PrivacyPolicyComponent));
